import { format, fromUnixTime, intervalToDuration, isValid } from 'date-fns';

/**
 * Uses Intl.DateTimeFormat to get the timezone abbreviation, this is a native function in most browsers
 * @returns Timezone abbreviation (ex: 'PST')
 */
export function getTimezoneAbbreviation(): string {
	// If Intl is not supported, return an empty string
	if (typeof Intl === 'undefined') {
		return '';
	}

	// Get the current date
	const now = new Date();

	// Get the time zone abbreviation using Intl.DateTimeFormat
	const timezoneAbbreviation = (
		Intl.DateTimeFormat('en', { timeZoneName: 'short' })
			.formatToParts(now)
			.find((part) => part.type === 'timeZoneName') || { value: '' }
	).value;

	return timezoneAbbreviation;
}

/**
 * Gets the duration between two timestamps
 * @param firstTimestamp First timestamp
 * @param lastTimestamp Last timestamp
 * @returns Object with hours, minutes, and seconds
 */
export const getDurationFromUnix = (firstTimestamp: number, lastTimestamp: number) => {
	const firstTimeFromUnix = fromUnixTime(firstTimestamp);
	const lastTimeFromUnix = fromUnixTime(lastTimestamp);

	const { hours, minutes, seconds } = intervalToDuration({
		start: firstTimeFromUnix,
		end: lastTimeFromUnix
	});

	return { hours, minutes, seconds };
};

export const formatDate = (date: string) => {
	return isValid(new Date(date)) ? format(new Date(date), 'MMM dd, yyyy') : '-';
};

/**
 * Converts Date object to the unix format we're using to sync BE & FE
 */
export const dateToUnixFormat = (date: Date) => Math.floor(date.getTime() / 1000);

/**
 * Converts standardized unix format (between BE and FE) to Date object
 */
export const unixFormatToDate = (unix: number) => new Date(unix * 1000);
