import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { filesColumns } from 'components/files-tables/files-table-columns';
import { Table } from 'components/Table/table';
import { ListFilesResponse, ListFilesResponseItem } from 'redux/features/library';

type FolderFilesTableProps = {
	data: ListFilesResponse;
	className: string;
};

export const FolderFilesTable = ({ data, className }: FolderFilesTableProps) => {
	const table = useReactTable({
		columns: filesColumns,
		data,
		getCoreRowModel: getCoreRowModel()
	});

	return <Table<ListFilesResponseItem> table={table} className={className} />;
};
