import { css } from '@emotion/css';
import { mq } from 'cymantic-ui/dist/constants';
import { theme } from 'cymantic-ui/dist/theme';

export const container = css`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
	justify-content: center;
	max-width: 37.5rem;
	padding: ${theme.space.space2};
	width: 100%;
`;

export const circleContainer = css`
	align-self: center;
	background-color: ${theme.color.green100};
	border-radius: 50%;
	padding: ${theme.space.space20};
	width: fit-content;
`;

export const headerContainer = css`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
`;

export const formFieldsContainer = css`
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space24};
	width: 100%;
`;

export const signUpStyles = {
	gridContainer: css`
		column-gap: ${theme.space.space24};
		display: grid;
		grid-template-columns: 1fr;
		row-gap: ${theme.space.space24};

		${mq.tabletSmall} {
			grid-template-columns: 1fr 1fr;
		}
	`,
	passwordsRequirements: css`
		background: ${theme.color.grey100};
		border-radius: ${theme.borderRadius.radiusMD};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space4};
		padding: 10px;
		position: relative;
		width: 100%;

		::after {
			border: 10px solid transparent;
			border-bottom-color: #f0f0f0;
			bottom: 100%;
			content: '';
			left: 50%;
			margin-left: -10px;
			position: absolute;
		}
	`
};

export const homeAuthcontainer = css`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
	justify-content: center;

	> * {
		max-width: 20rem;
		width: 100%;
	}
`;
