import { PageHeader, PageHeaderLeftSkeleton } from 'components/PageHeader/PageHeader';
import { FlexBox, SkeletonAreaLoader } from 'cymantic-ui';

export const SettingsSkeleton = () => {
	return (
		<>
			<PageHeader>
				<PageHeaderLeftSkeleton />
			</PageHeader>
			<FlexBox
				height="100%"
				overflowY="auto"
				backgroundColor="grey50"
				padding="space32"
				gap="space32"
			>
				<SkeletonAreaLoader height="10rem" width="100%" />
				<SkeletonAreaLoader height="30rem" width="100%" />
			</FlexBox>
		</>
	);
};
