import { RouterLink } from 'cymantic-ui/dist/atomic-components';
import { FileType } from 'redux/features/library';
import { useFormattedEncryptedUrl } from 'routes/detail-page/helpers';

export const formatFileLink = (type: FileType, id: string, index?: string) => {
	switch (type) {
		case 'transcript':
			return `/content/transcript/shannon/${id}`;
		case 'pdf':
			return `/content/pdf/${index}/${id}`;
		case 'web':
			return `/content/web/${index}/${id}`;
		default:
			return '';
	}
};

type ContentPageLinkProps = {
	type: FileType;
	id: string;
	label: string;
	index: string;
};

export const ContentPageLink = ({ id, label, type, index }: ContentPageLinkProps) => {
	const encryptedPath = useFormattedEncryptedUrl();

	const link = encryptedPath(formatFileLink(type, id, index));

	return <RouterLink to={link} label={label} />;
};
