import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import { FolderLink } from 'components/FolderLink/FolderLink';
import { PageContent } from 'components/page-content/page-content';
import { PageSection } from 'components/PageSection/PageSection';
import { TableNoData, TableSkeleton } from 'components/Table/helpers';
import { CenterContent, SkeletonAreaLoader, Text } from 'cymantic-ui/dist/atomic-components';
import { Folder } from 'redux/features/library';
import { LibraryFolderRow } from 'routes/Library/LibraryLayout';
import { LibraryTableWrapper } from 'routes/Library/LibraryTable/LibraryTableWrapper';

export const LibraryPageEmpty = () => (
	<CenterContent isFullHeight>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH4" textAlign="center">
					You do not have any folders or files yet.
				</Text>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					When files and folders are added, they will appear here.
				</Text>
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

export const LibraryPageSkeleton = () => (
	<PageContent>
		<PageSection title=" " isLoading>
			<LibraryFolderRow>
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
				<SkeletonAreaLoader height="46px" width="120px" />
			</LibraryFolderRow>
		</PageSection>
		<PageSection title=" " description=" " isLoading>
			<TableSkeleton />
		</PageSection>
	</PageContent>
);

type LibraryPageContentProps = {
	data: Folder;
};

export const LibraryPageContent = ({ data }: LibraryPageContentProps) => {
	const hasSubFolders = !!data && data.items.length > 0;
	const hasFiles = !!data && data?.file_count >= 1;

	return (
		<PageContent>
			{hasSubFolders && (
				<PageSection title="Folders">
					<LibraryFolderRow>
						{data.items.map((subfolder) => {
							return (
								<FolderLink
									key={subfolder.id}
									label={subfolder.name}
									to={`/library/folder/${subfolder.id}`}
								/>
							);
						})}
					</LibraryFolderRow>
				</PageSection>
			)}
			<PageSection
				title="Recently Viewed Files"
				description="Recently viewed files from all folders"
			>
				{hasFiles ? (
					<LibraryTableWrapper folderId="" fileCount={data.file_count} />
				) : (
					<TableNoData message="No recently viewed files found." />
				)}
			</PageSection>
		</PageContent>
	);
};
