import { useAppSelector } from 'redux/hooks';
import { encrypt, generatedIV } from 'utilities/encryption';

export const useFormattedEncryptedUrl = () => {
	const { user } = useAppSelector((state) => state.auth);

	const formatEncryptedUrl = (path: string) => {
		const iv = generatedIV();
		// Split the path to separate into identifiable parts
		const [, fileType, detail, category, fileId] = path.split('/');
		// Encrypt the category part using the user's URL key and the generated IV
		const encryptedCategory = encodeURIComponent(encrypt(category, user.urlKey ?? '', iv));
		// Reconstruct the URL with the encrypted category part, omitting any undefined or empty segments
		const url = [fileType, detail, encryptedCategory, fileId].filter(Boolean).join('/');

		return `/${url}`;
	};

	return formatEncryptedUrl;
};
