import { Sidebar } from 'components/Sidebar/Sidebar';
import { FlexBox } from 'cymantic-ui/dist/atomic-components/flex-box';
import * as React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setUserData, useLazyGetUserDataQuery } from 'redux/features/auth';
import { useAppDispatch } from 'redux/hooks';
import { getFeatureFlags } from 'utilities/get-feature-flags';

export const LeftSidebarPageLayout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const { isAuthEnabled } = getFeatureFlags();

	const [getUserData] = useLazyGetUserDataQuery();

	React.useEffect(() => {
		if (!isAuthEnabled) return;
		getUserData().then((res) => {
			const { data } = res;

			dispatch(
				setUserData({
					email: data?.email,
					name: `${data?.first_name} ${data?.last_name}`,
					urlKey: data?.urlKey
				})
			);
		});
	}, [dispatch, getUserData, isAuthEnabled]);

	React.useEffect(() => {
		const search = new URLSearchParams(location.search);

		navigate({ search: search.toString() }, { replace: true, state: {} });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<FlexBox direction="row" height="100vh" width="100%">
			<Sidebar />
			<FlexBox height="100%" width="100%" overflowX="auto" overflowY="hidden">
				<Outlet />
			</FlexBox>
		</FlexBox>
	);
};
