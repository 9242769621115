import { createColumnHelper } from '@tanstack/react-table';
import { ContentPageLink } from 'components/files-tables/files-table-helpers';
import { DateFormat } from 'cymantic-ui/dist/atomic-components';
import { fileTypeNames, ListFilesResponseItem } from 'redux/features/library';
import { rightAlignedCell } from 'routes/Library/LibraryTable/LibraryTable.styles';

const columnHelper = createColumnHelper<ListFilesResponseItem>();

const nameColumn = columnHelper.accessor('name', {
	id: 'name',
	header: () => 'Name',
	cell: ({ getValue, row }) => (
		<ContentPageLink
			type={row.original.type}
			id={row.original.cuid}
			label={getValue()}
			index={row.original.index}
		/>
	)
});

const typeColumn = columnHelper.accessor('type', {
	id: 'type',
	cell: ({ getValue }) => fileTypeNames[getValue()],
	header: () => 'Type'
});

const createdAtColumn = columnHelper.accessor('createdAt', {
	id: 'createdAt',
	cell: ({ getValue }) => (
		<span className={rightAlignedCell}>
			<DateFormat timestamp={getValue().datetime} variant={getValue().displayType} />
		</span>
	),
	header: () => <span className={rightAlignedCell}>Date Created</span>
});

const viewedAtColumn = columnHelper.accessor('viewedAt', {
	id: 'viewedAt',
	cell: ({ getValue }) => {
		const value = getValue();
		return (
			<span className={rightAlignedCell}>
				{value && <DateFormat timestamp={value.datetime} variant={value.displayType} />}
			</span>
		);
	},
	header: () => <span className={rightAlignedCell}>Last Viewed</span>
});

export const recentFilesColumns = [nameColumn, typeColumn, viewedAtColumn];
export const filesColumns = [nameColumn, typeColumn, createdAtColumn];
