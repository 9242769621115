import { AuthError, signUp } from 'aws-amplify/auth';
import { container, formFieldsContainer, signUpStyles } from 'components/Auth/auth.styles';
import { AuthHeader } from 'components/Auth/auth-components';
import { formatAllCharacterTypes } from 'components/Auth/auth-helper';
import { ErrorAlert, ErrorAlertKey } from 'components/Auth/error-alert';
import { PasswordRequirementsList } from 'components/Auth/password-requirements';
import {
	Button,
	FieldVertical,
	FlexBox,
	FormEnhancer,
	InlineAlert,
	Input,
	RouterLink,
	ScreenReaderOnly,
	Text
} from 'cymantic-ui/dist/atomic-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { setAuthError, setAuthInProgress } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { confirmRoute, signInRoute } from 'routes/helper';
import {
	containsBothCases,
	containsNumber,
	containsSpecialCharacterOrSpace,
	isPasswordLongEnough,
	isValidEmail,
	noLeadingOrTrailingSpace
} from 'utilities/string-validation';

export type SignUpFormFields = {
	email: string;
	firstName: string;
	lastName: string;
	newPassword: string;
};

export const SignUp = () => {
	const form = useForm<SignUpFormFields>({ criteriaMode: 'all', mode: 'onSubmit' });
	const navigate = useNavigate();
	const authError = useAppSelector((state) => state.auth?.authError);
	const { isAuthInProgress } = useAppSelector((state) => state.auth);
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		watch,
		formState: {
			errors,
			dirtyFields: { newPassword }
		}
	} = form;

	const handleSignUp = async (data: SignUpFormFields) => {
		if (isAuthInProgress) return;
		dispatch(setAuthInProgress(true));
		try {
			const { email, firstName, lastName, newPassword: password } = data;
			const res = await signUp({
				username: email,
				password,
				options: {
					userAttributes: { given_name: firstName, family_name: lastName },
					autoSignIn: true
				}
			});

			if (res.nextStep.signUpStep === 'CONFIRM_SIGN_UP') {
				navigate(`/${confirmRoute}`, { state: { email: data.email, isSignIn: false } });
			}
			dispatch(setAuthError(undefined));
		} catch (error) {
			if (error instanceof AuthError) {
				dispatch(setAuthError(error.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	};

	return (
		<FormEnhancer
			onSubmit={handleSubmit(handleSignUp)}
			aria-describedby="sign-up-form-description"
			className={container}
			form={form}
		>
			<AuthHeader title="Sign Up">Sign up to start exploring</AuthHeader>
			<ScreenReaderOnly>
				<div id="sign-up-form-description">All fields are required.</div>
			</ScreenReaderOnly>
			<div className={formFieldsContainer}>
				<FieldVertical>
					<Input
						{...register('email', {
							required: {
								value: true,
								message: 'Please enter a email address'
							},
							validate: (value) =>
								isValidEmail(value) || 'Please enter a valid email address'
						})}
						label="Email"
						autoComplete="email"
						isInvalid={!!errors.email?.type}
						placeholder="Enter email"
					>
						{errors.email && (
							<InlineAlert variant="error" size="xxs">
								{errors.email?.message}
							</InlineAlert>
						)}
					</Input>
				</FieldVertical>
				<div className={signUpStyles.gridContainer}>
					<FieldVertical>
						<Input
							{...register('firstName', {
								required: {
									value: true,
									message: 'Please enter your first name'
								}
							})}
							label="First Name"
							autoComplete="given-name"
							isInvalid={!!errors.firstName?.type}
							placeholder="Enter first name"
						>
							{errors.firstName && (
								<InlineAlert variant="error" size="xxs">
									{errors.firstName?.message}
								</InlineAlert>
							)}
						</Input>
					</FieldVertical>
					<FieldVertical>
						<Input
							{...register('lastName', {
								required: {
									value: true,
									message: 'Please enter your last name'
								}
							})}
							label="Last Name"
							autoComplete="family-name"
							isInvalid={!!errors.lastName?.type}
							placeholder="Enter last name"
						>
							{errors.lastName && (
								<InlineAlert variant="error" size="xxs">
									{errors.lastName?.message}
								</InlineAlert>
							)}
						</Input>
					</FieldVertical>
				</div>
				<FieldVertical>
					<Input
						{...register('newPassword', {
							required: {
								value: true,
								message: 'Please enter a password'
							},
							validate: {
								isPasswordLongEnough: (value) =>
									isPasswordLongEnough(value) ||
									'Password must be at least 8 characters.',
								hasAllCharacterTypes: (value) => {
									const meetsCharacterRequirements =
										containsNumber(value) &&
										containsBothCases(value) &&
										containsSpecialCharacterOrSpace(value);

									return (
										meetsCharacterRequirements || formatAllCharacterTypes(value)
									);
								},
								noLeadingOrTrailingSpace: (value) =>
									noLeadingOrTrailingSpace(value) ||
									'Password may not contain a leading or trailing space.'
							}
						})}
						label="Password"
						type="password"
						auto-complete="new-password"
						isInvalid={!!errors.newPassword?.type}
						placeholder="Enter password"
					>
						{errors.newPassword && (
							<InlineAlert variant="error" size="xxs">
								{errors.newPassword?.types?.required ||
									(errors.newPassword?.types &&
										Object.values(errors?.newPassword?.types).join(' '))}
							</InlineAlert>
						)}
					</Input>
				</FieldVertical>
			</div>
			<PasswordRequirementsList
				password={watch('newPassword')}
				isNewPasswordDirty={newPassword}
			/>
			{authError && (
				<FlexBox width="100%">
					<ErrorAlert authType="signUp" errorType={authError as ErrorAlertKey} />
				</FlexBox>
			)}
			<Button
				label="Sign Up"
				type="submit"
				variant="primary"
				isFullWidth
				isLoading={isAuthInProgress}
				subVariant="default"
				size="md"
			/>

			<Text variant="bodyXS" color="grey600">
				Already have an account? <RouterLink to={`/${signInRoute}`} label="Sign In" />
			</Text>
		</FormEnhancer>
	);
};
