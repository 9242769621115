import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { RouterLink } from 'cymantic-ui/dist/atomic-components/link';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import { userHomeRoute } from 'routes/helper';

const NoMatchPage = () => (
	<CenterContent>
		<CenteredLayoutContainer>
			<CenteredLayoutSubContainer>
				<Text tag="h1" variant="headerH1" textAlign="center">
					Oops!
				</Text>
				<Text tag="p" variant="bodyM" color="grey700" textAlign="center">
					This page does not exist
				</Text>
			</CenteredLayoutSubContainer>
			<CenteredLayoutSubContainer>
				<Text tag="p" variant="bodySM" color="grey700" textAlign="center">
					Return to <RouterLink to={userHomeRoute} label="Homepage" />.
				</Text>
			</CenteredLayoutSubContainer>
		</CenteredLayoutContainer>
	</CenterContent>
);

export default NoMatchPage;
