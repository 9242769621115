import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme/index';

const containerBase = css`
	align-items: flex-end;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space8};
	justify-content: flex-end;
	z-index: 9999;
`;

export const container = css`
	${containerBase}
	bottom: 1.5rem;
	position: absolute;
	right: 2rem;
`;

export const meetingContainer = css`
	${containerBase}
	bottom: 1.25rem;
	position: absolute;
	right: 1rem;
`;

export const initialPosition = css({
	position: 'relative',
	right: '-75px',
	scaleX: 0.1,
	opacity: 0
});

export const toastDisplay = css({ position: 'relative', right: 0, scaleX: 1, opacity: 1 });

export const toastTransition = {
	type: 'spring',
	stuffness: 300,
	damping: 20
};
