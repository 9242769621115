import { Product } from 'cymantic-ui/dist/atomic-components/logo';

const products: Product[] = ['Ruthi', 'Cymantix', 'Discover', 'Cori', 'Shannon'];

const productTypeGuard = (product: string): product is Product => {
	return products.includes(product as Product);
};

const productName = process.env.REACT_APP_PRODUCT_NAME || 'Ruthi';
const productKey = process.env.REACT_APP_PRODUCT_KEY || 'Ruthi';

export function getProduct() {
	return {
		productName,
		productKey: productTypeGuard(productKey) ? productKey : 'Ruthi'
	};
}
