import { TokenCheck, UserResponse } from 'redux/features/auth/auth.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const authQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		checkToken: builder.query<TokenCheck, void>({
			query: () => 'token'
		}),
		getUserData: builder.query<UserResponse, void>({
			query: () => 'userInfo'
		})
	})
});

export const {
	useCheckTokenQuery,
	useLazyCheckTokenQuery,
	useGetUserDataQuery,
	useLazyGetUserDataQuery
} = authQueries;
