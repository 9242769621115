import { signUpStyles } from 'components/Auth/auth.styles';
import { FlexBox, Icon, IconName, Text } from 'cymantic-ui/dist/atomic-components';
import { Color } from 'cymantic-ui/dist/theme';
import * as React from 'react';
import {
	containsBothCases,
	containsNumber,
	containsSpecialCharacterOrSpace,
	isPasswordLongEnough,
	noLeadingOrTrailingSpace
} from 'utilities/string-validation';

function validatePassword(password?: string) {
	if (!password) {
		return {
			isPasswordLongEnough: false,
			containsNumber: false,
			containsBothCases: false,
			containsSpecialCharacterOrSpace: false,
			noLeadingOrTrailingSpace: false
		};
	}

	return {
		isPasswordLongEnough: isPasswordLongEnough(password),
		containsNumber: containsNumber(password),
		containsBothCases: containsBothCases(password),
		containsSpecialCharacterOrSpace: containsSpecialCharacterOrSpace(password),
		noLeadingOrTrailingSpace: noLeadingOrTrailingSpace(password)
	};
}

type PasswordValidationResult =
	| 'isPasswordLongEnough'
	| 'containsNumber'
	| 'containsBothCases'
	| 'containsSpecialCharacterOrSpace'
	| 'noLeadingOrTrailingSpace';
type PasswordIcon = Extract<IconName, 'X' | 'Check' | 'Minus'>;
type PasswordColor = Extract<Color, 'red600' | 'green600' | 'grey600'>;
type ValidationStatus = 'success' | 'danger' | 'neutral';

// Define interface for password requirements to improve readability
type PasswordRequirements = {
	icon: PasswordIcon;
	color: PasswordColor;
};

type ValidationListItem = {
	id: number;
	text: string;
	validationType: PasswordValidationResult;
};

// Create a centralized place for defining validation related information
const validationStatusInfo: Record<ValidationStatus, PasswordRequirements> = {
	success: { icon: 'Check', color: 'green600' },
	danger: { icon: 'X', color: 'red600' },
	neutral: { icon: 'Minus', color: 'grey600' }
};

const validationList: ValidationListItem[] = [
	{
		id: 1,
		text: 'Password must contain at least 8 characters',
		validationType: 'isPasswordLongEnough'
	},
	{ id: 2, text: 'Password must contain a number', validationType: 'containsNumber' },
	{
		id: 3,
		text: 'Password must contain both upper and lower case letters',
		validationType: 'containsBothCases'
	},
	{
		id: 4,
		text: 'Password must contain a special character or a space',
		validationType: 'containsSpecialCharacterOrSpace'
	},
	{
		id: 5,
		text: 'Password must not contain a leading or trailing space',
		validationType: 'noLeadingOrTrailingSpace'
	}
];

type PasswordRequirementsListProps = {
	isNewPasswordDirty?: boolean;
	password: string;
};

export const PasswordRequirementsList = ({
	password,
	isNewPasswordDirty
}: PasswordRequirementsListProps) => {
	const errors = validatePassword(password);

	const getValidationListKey = React.useCallback(
		(validation: PasswordValidationResult): ValidationStatus => {
			if (!isNewPasswordDirty) return 'neutral';
			return errors?.[validation] ? 'success' : 'danger';
		},
		[errors, isNewPasswordDirty]
	);

	return (
		<div className={signUpStyles.passwordsRequirements}>
			<Text color="grey900" variant="bodyXXS">
				Password Requirements
			</Text>
			{validationList.map((item) => {
				const statusInfo = validationStatusInfo[getValidationListKey(item?.validationType)];
				return (
					<FlexBox key={item.id} direction="row" gap="space8">
						<Icon icon={statusInfo.icon} color={statusInfo.color} />
						<Text color="grey700" variant="bodyXXS">
							{item.text}
						</Text>
					</FlexBox>
				);
			})}
		</div>
	);
};
