import { css } from '@emotion/css';
import { mq } from 'cymantic-ui/dist/constants';
import { theme } from 'cymantic-ui/dist/theme';

export const transcriptRow = {
	root: css`
		column-gap: 1rem;
		display: grid;
		grid-template-areas:
			'timestamp speaker'
			'. utterance';
		grid-template-columns: 75px minmax(150px, 1fr);
		grid-template-rows: auto;
		padding: ${theme.space.space8} 0;
		row-gap: ${theme.space.space4};
		word-break: break-all;

		&:not(:first-child) {
			border-top: 1px solid ${theme.color.grey200};
		}

		${mq.tabletSmall} {
			grid-template-areas: 'timestamp speaker utterance';
			grid-template-columns: 75px 150px minmax(150px, 1fr);
		}
	`,
	timestamp: css`
		display: flex;
		grid-area: timestamp;
		justify-content: flex-end;
	`,
	speaker: css`
		display: flex;
		grid-area: speaker;
		justify-content: flex-start;
	`,
	utterance: css`
		display: flex;
		grid-area: utterance;
		justify-content: flex-start;
	`
};

export const transcriptFile = {
	info: css`
		border-bottom: 1px solid ${theme.color.grey300};
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space8};
		padding: ${theme.space.space24} ${theme.space.space32};
	`,
	transcript: css`
		background: ${theme.color.grey50};
		padding: ${theme.space.space24} ${theme.space.space32};
	`
};
