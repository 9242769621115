export function isPasswordLongEnough(password: string, length = 8) {
	return password.length >= length;
}

export function containsNumber(password: string) {
	return /\d/.test(password);
}

export function containsBothCases(password: string) {
	const hasUpperCase = /[A-Z]/.test(password);
	const hasLowerCase = /[a-z]/.test(password);
	return hasUpperCase && hasLowerCase;
}

export function containsSpecialCharacterOrSpace(password: string) {
	return /[!@#$%^&*()_+\-={};'":\\|,.<>/?\s]+/.test(password);
}

export function noLeadingOrTrailingSpace(password: string) {
	return password.length > 0 && password === password.trim();
}

export function isValidEmail(email: string) {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return regex.test(email) && !email.includes('..');
}
