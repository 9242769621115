import { Unauthorized } from 'components/Auth/auth-components';
import { checkIsUnauthorized } from 'components/Auth/auth-helper';
import { PageContent } from 'components/page-content/page-content';
import { AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { TranscriptContent, useGetDetailPageDataQuery } from 'redux/features/library';
import {
	TranscriptFileContent,
	TranscriptFileError,
	TranscriptFileSkeleton
} from 'routes/detail-page/transcript-file/transcript-file-states';
import { PageTransition } from 'routes/Library/LibraryTransition';

export const TranscriptFilePage = () => {
	const { fileId } = useParams();

	const { data, isError, isLoading, isSuccess, error } = useGetDetailPageDataQuery({
		cuid: fileId ?? ''
	});

	const hasData = isSuccess && !!data;
	const hasHeader = isLoading || hasData;

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<PageContent isFullWidth>
			<AnimatePresence>
				{isLoading && (
					<PageTransition key="transcriptSkeleton" hasHeader={hasHeader}>
						<TranscriptFileSkeleton />
					</PageTransition>
				)}
				{isError && (
					<PageTransition delay={0.3} key="transcriptError" hasHeader={hasHeader}>
						<TranscriptFileError />
					</PageTransition>
				)}
				{hasData && (
					<PageTransition delay={0.3} key="transcriptContent" hasHeader={hasHeader}>
						<TranscriptFileContent data={data.content as TranscriptContent} />
					</PageTransition>
				)}
			</AnimatePresence>
		</PageContent>
	);
};
