import { HomePageAuthRedirect } from 'components/Auth/auth-components';
import { useSignOut } from 'components/Auth/use-sign-out';
import {
	CenteredLayoutContainer,
	CenteredLayoutSubContainer
} from 'components/CenteredLayout/centered-layout';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { Text } from 'cymantic-ui/dist/atomic-components/text';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/hooks';

const FailedAuthPage = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { cause } = useParams();
	const { handleSignOut } = useSignOut();

	React.useEffect(() => {
		if (!cause) {
			navigate('/');
		}
		handleSignOut();
	}, [dispatch, navigate, cause, handleSignOut]);

	return (
		<CenterContent>
			<CenteredLayoutContainer>
				<CenteredLayoutSubContainer>
					<Text tag="h1" variant="headerH3" textAlign="center">
						{cause === 'expired' && 'Your session has expired'}
						{cause === 'invalid' && 'Your session authentication was not valid'}
						{cause === 'unknown' && 'Your session failed to authenticate'}
					</Text>
					<Text tag="p" color="grey600" textAlign="center">
						Please sign in to continue exploring your data.
					</Text>
				</CenteredLayoutSubContainer>
				<HomePageAuthRedirect />
			</CenteredLayoutContainer>
		</CenterContent>
	);
};

export default FailedAuthPage;
