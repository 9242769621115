import {
	ClusterQueryInput,
	ClusterResponse,
	DocPreviewRequest,
	DocPreviewResponse,
	DocsRequest,
	DocsResponse
} from 'redux/features/cluster/cluster.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const clusterQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		/**
		 * Getting clusters from basket: index, lens, scored_doc_ids (selectedMemberDocs)
		 * Getting clusters from search: index, lens, query, and optionally file
		 */
		getClusters: builder.query<ClusterResponse, ClusterQueryInput>({
			query: ({ index, lens, file, query, selectedMemberDocs, date }) => ({
				url: 'cluster',
				method: 'POST',
				body: JSON.stringify({
					index,
					lens,
					file,
					query,
					scored_doc_ids: selectedMemberDocs,
					date
				})
			})
		}),
		getDocPanel: builder.query<DocsResponse, DocsRequest>({
			query: ({ index, lens, docIds }) => {
				return {
					url: 'docs',
					method: 'POST',
					body: JSON.stringify({
						index,
						lens,
						doc_ids: docIds
					})
				};
			}
		}),
		getDocPreview: builder.query<DocPreviewResponse, DocPreviewRequest>({
			query: ({ index, cuid }) => {
				return {
					url: 'v2/docPreview',
					method: 'POST',
					body: JSON.stringify({
						index,
						cuid
					})
				};
			}
		})
	})
});

export const {
	useGetDocPanelQuery,
	useLazyGetDocPanelQuery,
	useGetClustersQuery,
	useLazyGetClustersQuery,
	useGetDocPreviewQuery,
	useLazyGetDocPreviewQuery
} = clusterQueries;
