import { circleContainer, headerContainer, homeAuthcontainer } from 'components/Auth/auth.styles';
import { Button, FlexBox, Icon, RouterLink, Text } from 'cymantic-ui/dist/atomic-components';
import Cookies from 'js-cookie';
import { Navigate, useNavigate } from 'react-router-dom';
import { signInRoute, signUpRoute } from 'routes/helper';
import { hasSignedInName } from 'utilities/cookies';

type AuthHeaderProps = {
	title: string;
	children?: React.ReactNode;
};
export const AuthHeader = ({ title, children }: AuthHeaderProps) => {
	return (
		<div className={headerContainer}>
			<Text variant="headerH3" tag="h1">
				{title}
			</Text>
			{children && (
				<Text variant="bodyM" color="grey600" textAlign="center">
					{children}
				</Text>
			)}
		</div>
	);
};

type PasswordResetSuccessProps = {
	title: string;
	content?: string;
	to: string;
	buttonLabel: string;
};

export const PasswordResetSuccess = ({
	title,
	content,
	to,
	buttonLabel
}: PasswordResetSuccessProps) => {
	const navigate = useNavigate();
	return (
		<FlexBox
			alignItems="center"
			justifyContent="center"
			gap="space32"
			maxWidth="37.5rem"
			width="100%"
			padding="space2"
		>
			<div className={circleContainer}>
				<Icon icon="Check" size="space40" color="green700" />
			</div>
			<AuthHeader title={title}>{content && content}</AuthHeader>
			<FlexBox width="100%" mt="space8">
				<Button
					label={buttonLabel}
					onClick={() => navigate(to)}
					variant="primary"
					subVariant="default"
					size="md"
				/>
			</FlexBox>
		</FlexBox>
	);
};

export const HomePageAuthRedirect = () => {
	const navigate = useNavigate();
	const hasSignedIn = Cookies.get(hasSignedInName) === 'true';

	return hasSignedIn ? (
		<div className={homeAuthcontainer}>
			<Button size="md" label="Sign In" onClick={() => navigate(`/${signInRoute}`)} />
			<Text color="grey600" variant="bodyXS" textAlign="center">
				Don&apos;t have an account? <RouterLink to={`/${signUpRoute}`} label="Sign Up" />
			</Text>
		</div>
	) : (
		<div className={homeAuthcontainer}>
			<Button size="md" label="Sign Up" onClick={() => navigate(`/${signUpRoute}`)} />
			<Text color="grey600" variant="bodyXS" textAlign="center">
				Already have an account? <RouterLink label="Sign In" to={`/${signInRoute}`} />
			</Text>
		</div>
	);
};

export const Unauthorized = () => {
	return <Navigate to="/signout/expired" />;
};
