import { pageContent, pageContentRow } from 'components/page-content/page-content.styles';
import { FlexBox } from 'cymantic-ui/dist/atomic-components/flex-box';
import { Color, theme } from 'cymantic-ui/dist/theme';
import * as React from 'react';

type PageContentProps = {
	direction?: 'row' | 'column';
	backgroundColor?: Extract<Color, 'grey50' | 'white'>;
	isFullWidth?: boolean;
	children: React.ReactNode;
};

export const PageContent = ({
	backgroundColor = 'grey50',
	direction = 'column',
	isFullWidth = false,
	children
}: PageContentProps) => (
	<main className={pageContent.root} style={{ backgroundColor: theme.color[backgroundColor] }}>
		{/* 
			IMPORTANT: 
			The plain div below ensures there is padding on the bottom of the page when page scrolls.
			
			**DO NOT REMOVE**
		*/}
		<div>
			<div
				className={direction === 'row' ? pageContent.innerRow : pageContent.innerColumn}
				style={{
					padding: isFullWidth ? `0 0 ${theme.space.space32} 0` : theme.space.space32
				}}
			>
				{children}
			</div>
		</div>
	</main>
);

export const PageContentColumn = ({ children }: { children: React.ReactNode }) => (
	<FlexBox flex="1" height="100%" gap="space32">
		{children}
	</FlexBox>
);

export const PageContentRow = ({ children }: { children: React.ReactNode }) => (
	<div className={pageContentRow}>{children}</div>
);
