import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { SummaryMethodsModel } from 'redux/features/cluster';
import {
	RuthiSummaryType,
	SummaryMethod,
	SummaryState
} from 'redux/features/summary/summary.definitions';
import { summaryTypeName } from 'utilities/cookies';
import { getAppDomain } from 'utilities/get-links';

const summaryTypeDefault = process.env.REACT_APP_DEFAULT_SUMMARY_METHOD;

const summaryTypeGuard = (type: string): type is RuthiSummaryType => {
	return ['abstractive', 'extractive'].includes(type);
};

export const getSummaryInitialType = () => {
	const summaryTypeCookie = Cookies.get(summaryTypeName);

	const defaultType =
		summaryTypeDefault && summaryTypeGuard(summaryTypeDefault)
			? summaryTypeDefault
			: 'abstractive';

	const summaryTypeInitialState =
		summaryTypeCookie && summaryTypeGuard(summaryTypeCookie) ? summaryTypeCookie : defaultType;

	return summaryTypeInitialState;
};

const initialState: SummaryState = {
	type: getSummaryInitialType(),
	indexMethods: {}
};

export const summarySlice = createSlice({
	name: 'summary',
	initialState,
	reducers: {
		setSummaryType: (state, action: PayloadAction<SummaryMethod | RuthiSummaryType>) => {
			const method = action.payload;

			// Handles mismatch in how BEs return the type/method
			const type = method.includes('abstract') ? 'abstractive' : 'extractive';

			Cookies.set(summaryTypeName, type, getAppDomain());

			const returnState: SummaryState = {
				...state,
				type
			};

			return returnState;
		},
		setIndexMethods: (
			state,
			action: PayloadAction<{ index: string; methods: SummaryMethodsModel }>
		) => {
			const { index, methods } = action.payload;
			const returnState: SummaryState = {
				...state,
				indexMethods: {
					...state.indexMethods,
					[index]: methods
				}
			};

			return returnState;
		}
	}
});

export const { setSummaryType } = summarySlice.actions;
export const summaryReducer = summarySlice.reducer;
