import {
	AddDiscoverToast,
	AddMeetToast,
	AddToastFunction,
	AddToastType,
	RemoveToastFunction,
	ToastNotification
} from 'components/toast-notifications/toast-notifications.definitions';
import {
	container,
	initialPosition,
	meetingContainer,
	toastDisplay,
	toastTransition
} from 'components/toast-notifications/toast-notifications.styles';
import { Toast, ToastProps } from 'cymantic-ui/dist/atomic-components/toast';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { meetRoute } from 'routes/helper';
import { v4 as uuid } from 'uuid';

type ToastNotificationsProps = {
	toasts: ToastNotification[];
	isMeetToast: boolean;
};

const ToastNotifications = ({ toasts, isMeetToast }: ToastNotificationsProps) => {
	return (
		<div className={isMeetToast ? meetingContainer : container}>
			<AnimatePresence>
				{toasts.map(({ id, ...rest }) => (
					<motion.div
						key={id}
						initial={initialPosition}
						animate={toastDisplay}
						transition={toastTransition}
						exit={{
							opacity: 0
						}}
					>
						<Toast key={id} themeVariant={isMeetToast ? 'dark' : 'light'} {...rest} />
					</motion.div>
				))}
			</AnimatePresence>
		</div>
	);
};

const isExistingToast = (
	array: ToastProps[],
	toast: Omit<AddToastType, 'id' | 'timeout' | 'target'>
) => {
	return array.some(
		(existingToast) =>
			existingToast.content === toast.content &&
			existingToast.title === toast.title &&
			existingToast.variant === toast.variant
	);
};

class ToastManager {
	private add: AddToastFunction | undefined;

	private remove: RemoveToastFunction | undefined;

	public addToast = (toast: AddToastType) => {
		if (this.add) {
			this.add(toast);
		}
	};

	public removeToast = (id: string) => {
		if (this.remove) {
			this.remove(id);
		}
	};

	public register = (add: AddToastFunction, remove: RemoveToastFunction) => {
		this.add = add;
		this.remove = remove;
	};
}

const toastManager = new ToastManager();

export const toast = {
	add: (newToast: AddToastType) => toastManager.addToast(newToast),
	success: (newToast: AddDiscoverToast) =>
		toastManager.addToast({ ...newToast, variant: 'success' }),
	info: (newToast: AddDiscoverToast) => toastManager.addToast({ ...newToast, variant: 'info' }),
	warning: (newToast: AddDiscoverToast) =>
		toastManager.addToast({ ...newToast, variant: 'warning' }),
	default: (newToast: AddDiscoverToast) =>
		toastManager.addToast({ ...newToast, variant: 'default' }),
	error: (newToast: AddDiscoverToast) => toastManager.addToast({ ...newToast, variant: 'error' }),
	meetUser: (newToast: AddMeetToast) => toastManager.addToast({ ...newToast, target: 'prod' }),
	meetDev: (newToast: AddMeetToast) =>
		toastManager.addToast({
			...newToast,
			content: `DEV ONLY: ${newToast.content}`,
			target: 'dev'
		})
};

export const GeneralToastNotifications = () => {
	const location = useLocation();
	const [toasts, setToasts] = React.useState<ToastNotification[]>([]);
	const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);
	const [portalTarget, setPortalTarget] = React.useState<HTMLElement | null>(null);
	const [isMeet, setIsMeet] = React.useState(false);

	React.useEffect(() => {
		setIsMeet(location.pathname.includes(`/${meetRoute}`));
	}, [isMeet, location.pathname]);

	React.useEffect(() => {
		if (!isMeet) {
			setPortalTarget(null);
			return;
		}
		let observer: MutationObserver;
		const checkAndSetTarget = () => {
			const meetTarget = document.getElementById('toast-notifications-target-container');
			if (meetTarget) {
				setPortalTarget(meetTarget);
				observer.disconnect();
			}
		};

		observer = new MutationObserver(checkAndSetTarget);
		observer.observe(document.body, {
			childList: true,
			subtree: true
		});

		checkAndSetTarget();

		// eslint-disable-next-line consistent-return
		return () => observer.disconnect();
	}, [isMeet]);

	const removeToast = React.useCallback((id: string) => {
		setToasts((allToasts) => allToasts.filter((t) => t.id !== id));
	}, []);

	const addToast = React.useCallback(
		(newToast: AddToastType) => {
			const ENV = process.env.REACT_APP_STAGE;
			const { timeout = 3000, target, ...rest } = newToast;

			const isDevToast = target && target === 'dev' && (ENV === 'test' || ENV === 'prod');

			const id = uuid();
			setToasts((allToasts) => {
				if (isExistingToast(allToasts, newToast) || isDevToast) {
					return allToasts;
				}
				return [...allToasts, { id, ...rest }];
			});

			if (timeout) {
				timeoutIdRef.current = setTimeout(() => {
					removeToast(id);
				}, timeout);
			}
		},
		[removeToast]
	);

	React.useEffect(() => {
		return () => {
			if (timeoutIdRef.current) {
				clearTimeout(timeoutIdRef.current);
			}
		};
	}, []);

	React.useEffect(() => {
		toastManager.register(addToast, removeToast);
	}, [addToast, removeToast]);

	if (!portalTarget) return <ToastNotifications toasts={toasts} isMeetToast={isMeet} />;

	return createPortal(<ToastNotifications toasts={toasts} isMeetToast={isMeet} />, portalTarget);
};
