import { Unauthorized } from 'components/Auth/auth-components';
import { checkIsUnauthorized } from 'components/Auth/auth-helper';
import { PageContent, PageContentColumn } from 'components/page-content/page-content';
import { FlexBox, Icon, SkeletonAreaLoader, Text } from 'cymantic-ui/dist/atomic-components';
import { useParams } from 'react-router-dom';
import { useGetDetailPageDataQuery, WebContent } from 'redux/features/library';
import { webFileStyles } from 'routes/detail-page/detail-page.styles';
import { DetailPageSummaryCard } from 'routes/detail-page/detail-page-summary-card';
import { Error } from 'routes/SearchPage/ClusterView/ClusterStates';
import { formatUrl } from 'utilities/string';

export const WebFilePage = () => {
	const { fileId = '' } = useParams();

	const { data, error, isLoading } = useGetDetailPageDataQuery({
		cuid: fileId
	});

	const hasError = !isLoading && !!error;
	const content = data?.content as WebContent;

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	const icon = content?.icon;
	const formattedUrl = formatUrl(content?.url);

	return (
		<PageContent direction="row">
			<PageContentColumn>
				{isLoading && <SkeletonAreaLoader width="100%" height="20rem" />}
				{hasError && <Error />}
				{!isLoading && !hasError && (
					<div className={webFileStyles.container}>
						<a
							className={webFileStyles.link}
							href={content?.url}
							target="_blank"
							rel="noreferrer"
						>
							<div className={webFileStyles.linkIconContainer}>
								<Icon icon="ExternalLink" size="space16" color="grey800" />
							</div>
							<div className={webFileStyles.content}>
								<div className={webFileStyles.imageContainer}>
									{icon ? (
										<img src={icon} alt={content?.name} />
									) : (
										<Icon icon="Link" size="space64" color="grey600" />
									)}
								</div>
								<FlexBox
									backgroundColor="white"
									gap="space4"
									padding="space24"
									width="100%"
								>
									{content?.name && (
										<Text tag="h5" variant="bodyM">
											{content?.name}
										</Text>
									)}
									{formattedUrl && (
										<Text variant="bodyXS" color="grey700">
											{formattedUrl}
										</Text>
									)}
								</FlexBox>
								{content?.description && (
									<Text variant="bodyXS" color="grey700">
										{content.description}
									</Text>
								)}
							</div>
						</a>
					</div>
				)}
			</PageContentColumn>
			<PageContentColumn>
				{data && !isLoading && <DetailPageSummaryCard file={data} />}

				{isLoading && <SkeletonAreaLoader width="100%" height="20rem" />}
			</PageContentColumn>
		</PageContent>
	);
};
