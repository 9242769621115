import { PageError } from 'components/PageError/PageError';
import { SkeletonTextLoader, Text } from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';
import { TranscriptContent } from 'redux/features/library';
import { transcriptFile } from 'routes/detail-page/transcript-file/transcript-file.styles';
import {
	TranscriptRow,
	TranscriptRowSkeleton
} from 'routes/detail-page/transcript-file/transcript-row';
import { getDurationFromUnix } from 'utilities/time-formatting';

export const TranscriptFileError = () => (
	<PageError
		title="An error occurred while retrieving your file"
		subtitle="Please try again later."
		alertVariant="error"
	/>
);

export const TranscriptFileSkeleton = () => (
	<>
		<div className={transcriptFile.info}>
			<SkeletonTextLoader variant="bodyXS" width="200px" />
			<SkeletonTextLoader variant="bodyXS" width="300px" />
		</div>
		<div className={transcriptFile.transcript}>
			{[...Array(10).keys()].map((i) => (
				<TranscriptRowSkeleton key={i} />
			))}
		</div>
	</>
);

type TranscriptFileContentProps = {
	data: TranscriptContent;
};

export const TranscriptFileContent = ({ data }: TranscriptFileContentProps) => {
	const participants = data.participants.join(', ');
	const hasTranscripts = data.transcript.length > 0;

	const [meetingDurationHours, setMeetingDurationHours] = React.useState(0);

	const chronologicalTranscript = data.transcript
		.map((item) => ({
			...item,
			timestamp: Number(item.timestamp)
		}))
		.sort((a, b) => {
			// NOTE: Tie break ONLY if timestamps match
			if (a.timestamp === b.timestamp) return a.tiebreak - b.tiebreak;

			return a.timestamp - b.timestamp;
		});

	const firstTimestamp = chronologicalTranscript[0].timestamp;
	const lastTimestamp = chronologicalTranscript[chronologicalTranscript.length - 1].timestamp;

	React.useEffect(() => {
		if (firstTimestamp && lastTimestamp) {
			const { hours } = getDurationFromUnix(firstTimestamp, lastTimestamp);

			if (hours && hours > 0) {
				setMeetingDurationHours(hours);
			}
		}
	}, [hasTranscripts, firstTimestamp, lastTimestamp]);

	return (
		<>
			{participants.length > 0 && (
				<div className={transcriptFile.info}>
					<Text color="grey900" variant="bodyXS">
						{participants}
					</Text>
				</div>
			)}
			<ul className={transcriptFile.transcript}>
				{firstTimestamp &&
					chronologicalTranscript.map((item) => {
						const { text, speakerName, timestamp } = item;
						return (
							<TranscriptRow
								key={`${item.speakerName}${item.timestamp}`}
								firstTimestamp={firstTimestamp}
								hasHours={meetingDurationHours > 0}
								speakerName={speakerName}
								text={text}
								timestamp={timestamp}
							/>
						);
					})}
			</ul>
		</>
	);
};
