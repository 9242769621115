import { AuthError, confirmResetPassword } from 'aws-amplify/auth';
import { container, formFieldsContainer } from 'components/Auth/auth.styles';
import { AuthHeader, PasswordResetSuccess } from 'components/Auth/auth-components';
import { formatAllCharacterTypes } from 'components/Auth/auth-helper';
import { ErrorAlert, ErrorAlertKey, useErrorMessage } from 'components/Auth/error-alert';
import { PasswordRequirementsList } from 'components/Auth/password-requirements';
import {
	Button,
	FieldVertical,
	FlexBox,
	FormEnhancer,
	InlineAlert,
	Input,
	LinkButton,
	ScreenReaderOnly,
	Text
} from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { setAuthError, setAuthInProgress } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetRequestRoute, signInRoute } from 'routes/helper';
import { formatEmail } from 'utilities/string';
import {
	containsBothCases,
	containsNumber,
	containsSpecialCharacterOrSpace,
	isPasswordLongEnough,
	noLeadingOrTrailingSpace
} from 'utilities/string-validation';

type ResetPasswordFormStep = 'request' | 'success';

export type ResetPasswordFormFields = {
	code: string;
	newPassword: string;
	confirmPassword: string;
};

export const ResetPasswordForm = () => {
	const form = useForm<ResetPasswordFormFields>({ criteriaMode: 'all', mode: 'onSubmit' });
	const [resetPasswordStep, setResetPasswordStep] =
		React.useState<ResetPasswordFormStep>('request');
	const { handleSendNewResetPasswordCode } = useErrorMessage();
	const { isAuthInProgress } = useAppSelector((state) => state.auth);
	const authError = useAppSelector((state) => state.auth?.authError);
	const location = useLocation();

	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		watch,
		formState: {
			errors,
			dirtyFields: { newPassword }
		}
	} = form;

	const email = location?.state?.email;

	const handleConfirmResetPassword = async (data: ResetPasswordFormFields) => {
		if (isAuthInProgress) return;
		dispatch(setAuthInProgress(true));
		try {
			await confirmResetPassword({
				username: email,
				newPassword: data.newPassword,
				confirmationCode: data.code
			});
			dispatch(setAuthError(undefined));
			setResetPasswordStep('success');
		} catch (error) {
			if (error instanceof AuthError) {
				dispatch(setAuthError(error?.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	};

	return (
		<FormEnhancer
			aria-describedby="resset-password-form-description"
			className={container}
			form={form}
			onSubmit={handleSubmit(handleConfirmResetPassword)}
		>
			{!email && <Navigate to={`/${resetRequestRoute}`} />}
			{resetPasswordStep === 'request' && email && (
				<>
					<AuthHeader title="Reset Password">
						{`We sent a password reset code to ${formatEmail(
							email
						)}. Please enter it below to reset
					your password.`}
					</AuthHeader>
					<ScreenReaderOnly>
						<div id="resset-password-form-description">All fields are required.</div>
					</ScreenReaderOnly>
					<div className={formFieldsContainer}>
						<FieldVertical>
							<Input
								{...register('code', {
									required: {
										value: true,
										message: 'Please enter your reset code'
									}
								})}
								autoComplete="one-time-code"
								label="Code"
								isInvalid={!!errors.code?.type}
								placeholder="Enter reset code"
							>
								{errors.code && (
									<InlineAlert variant="error" size="xxs">
										{errors.code?.message}
									</InlineAlert>
								)}
							</Input>
						</FieldVertical>
						<FieldVertical>
							<Input
								{...register('newPassword', {
									required: {
										value: true,
										message: 'Please enter a new password'
									},
									validate: {
										isPasswordLongEnough: (value) =>
											isPasswordLongEnough(value) ||
											'Password must be at least 8 characters.',
										hasAllCharacterTypes: (value) => {
											const meetsCharacterRequirements =
												containsNumber(value) &&
												containsBothCases(value) &&
												containsSpecialCharacterOrSpace(value);

											return (
												meetsCharacterRequirements ||
												formatAllCharacterTypes(value)
											);
										},
										noLeadingOrTrailingSpace: (value) =>
											noLeadingOrTrailingSpace(value) ||
											'Password may not contain a leading or trailing space.'
									}
								})}
								label="New Password"
								autoComplete="new-password"
								type="password"
								isInvalid={!!errors.newPassword?.type}
								placeholder="Enter new password"
							>
								{errors.newPassword && (
									<InlineAlert variant="error" size="xxs">
										{errors.newPassword?.types?.required ||
											(errors.newPassword?.types &&
												Object.values(errors?.newPassword?.types).join(
													' '
												))}
									</InlineAlert>
								)}
							</Input>
						</FieldVertical>
						<PasswordRequirementsList
							password={watch('newPassword')}
							isNewPasswordDirty={newPassword}
						/>
						<FieldVertical>
							<Input
								{...register('confirmPassword', {
									required: {
										value: true,
										message: 'Please re-enter your new password'
									},
									validate: (value) =>
										value === form.watch('newPassword') ||
										'Passwords do not match'
								})}
								label="Confirm Password"
								type="password"
								isInvalid={!!errors.confirmPassword?.type}
								placeholder="Confirm new password"
							>
								{errors.confirmPassword && (
									<InlineAlert variant="error" size="xxs">
										{errors.confirmPassword?.message}
									</InlineAlert>
								)}
							</Input>
						</FieldVertical>
					</div>
					{authError && (
						<FlexBox width="100%">
							<ErrorAlert
								email={email}
								authType="resetPassword"
								errorType={authError as ErrorAlertKey}
							/>
						</FlexBox>
					)}
					<Button
						isFullWidth
						label="Reset Password"
						type="submit"
						variant="primary"
						isLoading={isAuthInProgress}
						subVariant="default"
						size="md"
					/>
					<Text variant="bodyXS" color="grey600">
						Didn’t receive a code?{' '}
						<LinkButton
							onClick={() => handleSendNewResetPasswordCode(email)}
							variant="bodyXS"
							label="Send a new code"
						/>
					</Text>
				</>
			)}
			{resetPasswordStep === 'success' && (
				<PasswordResetSuccess
					buttonLabel="Sign In"
					to={`/${signInRoute}`}
					title="Password Reset Successfully"
				/>
			)}
		</FormEnhancer>
	);
};
