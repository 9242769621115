import { fetchAuthSession } from 'aws-amplify/auth';
import { checkIsErrorCode, RTKQueryError } from 'redux/error-helpers';
import { getFeatureFlags } from 'utilities/get-feature-flags';
import {
	containsBothCases,
	containsNumber,
	containsSpecialCharacterOrSpace
} from 'utilities/string-validation';

export const formatAllCharacterTypes = (password: string) => {
	const conditions = [];

	if (!containsNumber(password)) {
		conditions.push('1 number');
	}
	if (!containsBothCases(password)) {
		conditions.push('1 upper and 1 lower case letter');
	}
	if (!containsSpecialCharacterOrSpace(password)) {
		conditions.push('1 special character');
	}

	let message = 'Password must contain ';
	if (conditions.length > 1) {
		// Replace the last comma with ', and'
		const lastCondition = conditions.pop();
		message += `${conditions.join(', ')}, and ${lastCondition}`;
	} else if (conditions.length === 1) {
		message += conditions[0];
	}

	return `${message}.`;
};

export const getAuthTokens = async () => {
	try {
		const session = await fetchAuthSession();

		return {
			tokenId: session.tokens?.idToken?.toString(),
			accessToken: session.tokens?.accessToken.toString()
		};
	} catch (error) {
		throw new Error(`Error in getAuthTokens: ${error}`);
	}
};

export async function getUserDataFromToken() {
	try {
		const { tokens } = await fetchAuthSession();
		const userDetails = tokens?.idToken?.payload;

		const name = `${userDetails?.firstName ?? ''}${
			userDetails?.lastName ? ` ${userDetails?.lastName}` : ''
		}`;

		return {
			name,
			email: userDetails?.email ?? '',
			cognitoUsername: userDetails?.['cognito:username']
		};
	} catch (error) {
		throw new Error('Failed to get user data from token');
	}
}

export const checkIsUnauthorized = (error?: RTKQueryError) => {
	const { isAuthEnabled } = getFeatureFlags();
	return checkIsErrorCode(401, error) && isAuthEnabled;
};
