import { Unauthorized } from 'components/Auth/auth-components';
import { checkIsUnauthorized } from 'components/Auth/auth-helper';
import { FolderFilesTable } from 'components/files-tables/folder-files-table';
import { RecentFilesTable } from 'components/files-tables/recent-files-table';
import { TableError, TableNoData, TableSkeleton } from 'components/Table/helpers';
import { TruncatedPagination } from 'cymantic-ui/dist/atomic-components/pagination';
import { AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { useLazyGetFilesQuery } from 'redux/features/library';
import { libraryTable, paginationWrapper } from 'routes/Library/LibraryTable/LibraryTable.styles';
import { PageTransition } from 'routes/Library/LibraryTransition';

type LibraryTableWrapperProps = {
	folderId: string;
	fileCount: number;
};

/**
 * Table states:
 * 1. @isLoading
 * 2. @hasError Loads error block
 * 3. @hasNoFiles Loads "Folder is empty" block
 * 4. @hasFiles Loads table
 * 5. @isUnauthorized Redirects to signout/expired
 */
export const LibraryTableWrapper = ({ folderId, fileCount = 0 }: LibraryTableWrapperProps) => {
	const [page, setPage] = React.useState(1);
	const [isPageLoading, setIsPageLoading] = React.useState(false);
	const [getFiles, { data: tableData, isLoading, isError, isSuccess, error }] =
		useLazyGetFilesQuery();

	const pageSize = 15;
	const minimumPageTransitionTime = 1000;
	const pageCount = Math.ceil(fileCount / pageSize);
	const isPaginated = pageCount > 1;

	const isTableLoading = isLoading || isPageLoading;
	const hasError = !isTableLoading && isError;
	const hasFiles = !isTableLoading && isSuccess && tableData && tableData.length > 0;
	const hasNoFiles = !isTableLoading && isSuccess && tableData && tableData.length === 0;

	React.useEffect(() => {
		getFiles({ folderId, page });
	}, [folderId, page, getFiles]);

	React.useEffect(() => {
		if (isLoading) {
			setIsPageLoading(true);
			return;
		}

		const timeout = setTimeout(() => setIsPageLoading(false), minimumPageTransitionTime);

		// eslint-disable-next-line consistent-return
		return () => clearTimeout(timeout);
	}, [page, isLoading]);

	const handleSetPage = (pageNum: number) => setPage(pageNum);

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<AnimatePresence>
			{isTableLoading && (
				<PageTransition key="tableLoader">
					<TableSkeleton />
				</PageTransition>
			)}
			{hasFiles && (
				<PageTransition key="tableFiles" delay={0.3}>
					{folderId ? (
						<FolderFilesTable data={tableData ?? []} className={libraryTable} />
					) : (
						<RecentFilesTable data={tableData ?? []} className={libraryTable} />
					)}
					{isPaginated && (
						<div className={paginationWrapper}>
							<TruncatedPagination
								setPage={handleSetPage}
								page={page}
								pageCount={pageCount}
							/>
						</div>
					)}
				</PageTransition>
			)}
			{/* hasNoFiles: should be caught in LibraryPage or LibraryFolderPage component, but if not it will be caught here */}
			{hasNoFiles && (
				<PageTransition key="tableNoFiles" delay={0.3}>
					<TableNoData message="This folder is currently empty." />
				</PageTransition>
			)}
			{hasError && (
				<PageTransition key="tableError" delay={0.3}>
					<TableError />
				</PageTransition>
			)}
		</AnimatePresence>
	);
};
