import { MainNav, MobileMenu, NavLink, NavList, useMediaQueries } from 'cymantic-ui';
import { getProduct } from 'utilities/get-product';

const HeaderHome = () => {
	const { productKey } = getProduct();
	const { isMobile } = useMediaQueries();
	const isMobileMenu = isMobile;

	return (
		<MainNav product={productKey}>
			{isMobileMenu ? (
				<MobileMenu>
					<NavLink label="About" href="https://www.cymantix.com" />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
				</MobileMenu>
			) : (
				<NavList ariaLabel="Links" gap="space48">
					<NavLink label="About" href="https://www.cymantix.com" isBlank />
					<NavLink label="Contact" href="mailto:support@cymantix.com" />
				</NavList>
			)}
		</MainNav>
	);
};

export default HeaderHome;
