import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const container = css`
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space48};
	padding: ${theme.space.space32};
`;

export const subContainer = css`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: ${theme.space.space16};
`;
