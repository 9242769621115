import { AuthError, autoSignIn, confirmSignUp } from 'aws-amplify/auth';
import { container, formFieldsContainer } from 'components/Auth/auth.styles';
import { AuthHeader, PasswordResetSuccess } from 'components/Auth/auth-components';
import { ErrorAlert, ErrorAlertKey, useErrorMessage } from 'components/Auth/error-alert';
import {
	Button,
	FieldVertical,
	FlexBox,
	FormEnhancer,
	InlineAlert,
	Input,
	LinkButton,
	ScreenReaderOnly,
	Text
} from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { setAuthError, setAuthInProgress, setSignedIn } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { signInRoute } from 'routes/helper';
import { formatEmail } from 'utilities/string';

// Note: success - when signing-in with not-yet-verified account, then confirming authorization code
// signIn function doesn't provide authoSignIn option

type ConfirmAccountFormSteps = 'confirm' | 'success';

type ConfirmAccountFormFields = {
	code: string;
};

export const ConfirmAccount = () => {
	const form = useForm<ConfirmAccountFormFields>({ mode: 'onSubmit' });
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = form;
	const authError = useAppSelector((state) => state.auth?.authError);
	const { isAuthInProgress } = useAppSelector((state) => state.auth);
	const [confirmAccountStep, setConfirmAccountStep] =
		React.useState<ConfirmAccountFormSteps>('confirm');
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { handleSendNewCode } = useErrorMessage();
	const email = location?.state?.email;
	const isSignIn = location?.state?.isSignIn as boolean;
	const formatedEmail = email ? formatEmail(email) : 'your email address';

	const signInUser = async () => {
		if (isAuthInProgress) return;

		dispatch(setAuthInProgress(true));
		try {
			const res = await autoSignIn();
			if (!res.isSignedIn) return;

			dispatch(setSignedIn(true));
			navigate('/dashboard');
		} catch (error) {
			if (error instanceof AuthError) {
				dispatch(setAuthError(error?.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	};

	const handleConfirmAccount = async (data: ConfirmAccountFormFields) => {
		if (isAuthInProgress) return;
		dispatch(setAuthInProgress(true));

		try {
			const res = await confirmSignUp({ username: email, confirmationCode: data.code });
			if (!res.isSignUpComplete) return;

			if (!isSignIn) {
				await signInUser();
			}

			dispatch(setAuthError(undefined));
			setConfirmAccountStep('success');
		} catch (error) {
			if (error instanceof AuthError) {
				dispatch(setAuthError(error?.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	};

	return (
		<FormEnhancer
			className={container}
			aria-describedby="confirm-account-form-description"
			form={form}
			onSubmit={handleSubmit(handleConfirmAccount)}
		>
			{!email && <Navigate to={`/${signInRoute}`} />}
			<>
				{confirmAccountStep === 'confirm' && (
					<>
						<AuthHeader
							title={isSignIn ? 'Account Confirmation Required' : 'Confirm Account'}
						>
							{`To sign in please enter the verification code sent to ${formatedEmail}.
							`}
							<Text tag="div" variant="bodyM" color="grey600">
								Can’t find your code?{' '}
								<LinkButton
									onClick={() => handleSendNewCode(email)}
									variant="bodyM"
									label="Send a new code"
								/>
							</Text>
						</AuthHeader>
						<ScreenReaderOnly>
							<div id="confirm-account-form-description">
								All fields are required.
							</div>
						</ScreenReaderOnly>
						<div className={formFieldsContainer}>
							<FieldVertical>
								<Input
									{...register('code', {
										required: {
											value: true,
											message: 'Please enter your verification code'
										}
									})}
									label="Verification Code"
									autoComplete="one-time-code"
									isInvalid={!!errors.code?.type}
									placeholder="Enter code"
								>
									{errors.code && (
										<InlineAlert variant="error" size="xxs">
											{errors.code?.message}
										</InlineAlert>
									)}
								</Input>
							</FieldVertical>
						</div>
						{authError && (
							<FlexBox width="100%">
								<ErrorAlert
									email={email}
									authType="confirmAccount"
									errorType={authError as ErrorAlertKey}
								/>
							</FlexBox>
						)}
						<Button
							label="Confirm Account"
							type="submit"
							isFullWidth
							variant="primary"
							subVariant="default"
							isLoading={isAuthInProgress}
							size="md"
						/>
						<Text variant="bodyXS" color="grey600">
							Didn’t receive a code?{' '}
							<LinkButton
								onClick={() => handleSendNewCode(email)}
								label="Send a new code"
							/>
						</Text>
					</>
				)}
				{confirmAccountStep === 'success' && (
					<PasswordResetSuccess
						buttonLabel="Sign In"
						to={`/${signInRoute}`}
						title="Account confirmed successfully"
					/>
				)}
			</>
		</FormEnhancer>
	);
};
