import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthTokens } from 'components/Auth/auth-helper';

const host = process.env.REACT_APP_SUMMARY_API_HOST;

const baseQuery = fetchBaseQuery({
	baseUrl: `${host}`,
	prepareHeaders: async (headers) => {
		const { tokenId } = await getAuthTokens();

		headers.set('Content-Type', 'application/json');
		headers.set('Authorization', `Bearer ${tokenId}`);

		return headers;
	}
});

export const summaryApiBase = createApi({
	reducerPath: 'summary-api',
	baseQuery,
	endpoints: () => ({})
});
