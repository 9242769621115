import { SummaryMethodsModel } from 'redux/features/cluster';
import { RuthiCUID, RuthiDateObjectModel, RuthiID } from 'redux/ruthi-api-base.definitions';

export type TranscriptItem = {
	speakerName: string;
	speakerId: string;
	corrected: boolean;
	timestamp: number;
	text: string;
};

export type Folder = RuthiID & {
	name: string;
	items: Folder[];
	file_count: number;
	parent_folder_id: string;
	parent_folder_name: string;
};

export type LibraryState = {
	folders?: Folder[];
};

export type ListFilesResponseItem = RuthiCUID & {
	name: string;
	index: string;
	createdAt: RuthiDateObjectModel;
	viewedAt?: RuthiDateObjectModel;
	extension: string;
	type: FileType;
};

export type ListFilesResponse = ListFilesResponseItem[];

export type ListFilesRequest = {
	folderId?: string;
	page?: number;
};

type TranscriptUtterance = {
	speakerName: string;
	text: string;
	timestamp: string;
	tiebreak: number;
};

export type TranscriptContent = {
	participants: string[];
	transcript: TranscriptUtterance[];
};

export type PdfContent = {
	url: string;
};

export type WebContent = {
	url: string;
	name?: string;
	description?: string;
	icon?: string;
};

export type FileType = 'transcript' | 'pdf' | 'web';

export const fileTypeNames: Record<FileType, string> = {
	transcript: 'Transcript',
	pdf: 'PDF',
	web: 'Webpage'
};

type ParentFolder = RuthiID & {
	name: string;
};

export type FileResponse = RuthiCUID & {
	name: string;
	createdAt?: RuthiDateObjectModel;
	content?: TranscriptContent | PdfContent | WebContent;
	contentField?: string;
	summaryMethods: SummaryMethodsModel;
	parent?: ParentFolder;
	type?: FileType;
};

export type FileRequest = RuthiCUID;
