import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from 'redux/features/auth';
import { clusterReducer } from 'redux/features/cluster';
import { libraryReducer } from 'redux/features/library';
import { searchReducer } from 'redux/features/search';
import { summaryReducer } from 'redux/features/summary/summary-slice';
import { meetApiBase } from 'redux/meet-api-base';
import { ruthiApiBase } from 'redux/ruthi-api-base';
import { summaryApiBase } from 'redux/summary-api-base';

const appReducer = combineReducers({
	auth: authReducer,
	cluster: clusterReducer,
	library: libraryReducer,
	search: searchReducer,
	summary: summaryReducer,
	[meetApiBase.reducerPath]: meetApiBase.reducer,
	[ruthiApiBase.reducerPath]: ruthiApiBase.reducer,
	[summaryApiBase.reducerPath]: summaryApiBase.reducer
});

type AppState = ReturnType<typeof appReducer> | undefined;
type AppAction = { type: 'RESET_APP' };

export const rootReducer = (state: AppState, action: AppAction) => {
	if (action.type === 'RESET_APP') {
		return appReducer(undefined, action);
	}
	return appReducer(state, action);
};

export const resetReduxState = () => ({
	type: 'RESET_APP'
});
