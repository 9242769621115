import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { meetApiBase } from 'redux/meet-api-base';
import { rootReducer } from 'redux/root-reducer';
import { ruthiApiBase } from 'redux/ruthi-api-base';
import { summaryApiBase } from 'redux/summary-api-base';

const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			meetApiBase.middleware,
			ruthiApiBase.middleware,
			summaryApiBase.middleware
		])
});

setupListeners(store.dispatch);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;
