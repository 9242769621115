import LibraryTree from 'components/LibraryTree/LibraryTree';
import { sidebar } from 'components/Sidebar/Sidebar.styles';
import { SidebarLink } from 'components/Sidebar/SidebarItems/SidebarItems';
import {
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuList
} from 'components/Sidebar/SidebarMenu/SidebarMenu';
import { UserDropdown } from 'components/UserDropdown/UserDropdown';
import { isLocalHost } from 'cymantic-ui';
import { Logo } from 'cymantic-ui/dist/atomic-components/logo';
import { Tooltip, TooltipContent } from 'cymantic-ui/dist/atomic-components/tooltip';
import { Link } from 'react-router-dom';
import {
	dashboardRoute,
	libraryRoute,
	meetRoute,
	settingsRoute,
	userHomeRoute
} from 'routes/helper';
import { getFeatureFlags } from 'utilities/get-feature-flags';
import { getProduct } from 'utilities/get-product';

// Mega menu inspiration: https://accessible-mega-menu.netlify.app/
export const Sidebar = () => {
	const { productKey } = getProduct();
	const { isAuthEnabled, isLibraryEnabled, isShannonEnabled } = getFeatureFlags();

	const isDashboardEnabled = isShannonEnabled || isLibraryEnabled;

	return (
		<nav role="navigation" aria-label="Main Menu" className={sidebar.root}>
			<ul role="menubar" className={sidebar.topContainer}>
				<div className={sidebar.top}>
					<li>
						<Link to={userHomeRoute} aria-label="Home">
							<Logo product={productKey} height="2.5rem" width="2.5rem" />
						</Link>
					</li>
					{isDashboardEnabled && (
						<li>
							<Tooltip size="sm" placement="right">
								<SidebarLink
									ariaLabel="Dashboard"
									icon="Grid"
									to={`/${dashboardRoute}`}
								/>
								<TooltipContent>Dashboard</TooltipContent>
							</Tooltip>
						</li>
					)}
					<li>
						<Tooltip size="sm" placement="right">
							<SidebarLink ariaLabel="Search" icon="Search" to="/search" />
							<TooltipContent>Search</TooltipContent>
						</Tooltip>
					</li>

					{isLibraryEnabled && (
						<li>
							<SidebarMenu title="Library">
								<SidebarMenuButton
									to={`/${libraryRoute}`}
									icon="BookOpen"
									isActive={false}
								/>
								<SidebarMenuList>
									<li>
										<LibraryTree />
									</li>
								</SidebarMenuList>
							</SidebarMenu>
						</li>
					)}
					{isShannonEnabled && (
						<li>
							<Tooltip size="sm" placement="right">
								<SidebarLink
									ariaLabel="Convene Meetings"
									icon="Video"
									to={`/${meetRoute}`}
								/>
								<TooltipContent>Convene</TooltipContent>
							</Tooltip>
						</li>
					)}

					{isLocalHost() && (
						<li>
							<Tooltip size="sm" placement="right">
								<SidebarLink ariaLabel="Sandbox" icon="Book" to="/sandbox" />
								<TooltipContent>Sandbox</TooltipContent>
							</Tooltip>
						</li>
					)}
				</div>
				{isAuthEnabled && (
					<li>
						<Tooltip size="sm" placement="right">
							<SidebarLink
								ariaLabel="Settings"
								icon="Settings"
								to={`/${settingsRoute}`}
							/>
							<TooltipContent>Settings</TooltipContent>
						</Tooltip>
					</li>
				)}
			</ul>
			{isAuthEnabled && (
				<div>
					<div className={sidebar.userDropdown}>
						<UserDropdown />
					</div>
				</div>
			)}
		</nav>
	);
};
