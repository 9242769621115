import {
	FileRequest,
	FileResponse,
	Folder,
	ListFilesRequest,
	ListFilesResponse
} from 'redux/features/library/library.definitions';
import { ruthiApiBase } from 'redux/ruthi-api-base';

export const libraryQueries = ruthiApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getFiles: builder.query<ListFilesResponse, ListFilesRequest>({
			query: ({ folderId, page }) => ({
				url: 'listFiles',
				method: 'POST',
				body: JSON.stringify({
					page,
					folderId
				})
			})
		}),
		getFile: builder.query<FileResponse, string>({
			query: (fileId) => `v2/getFile/${fileId}`
		}),
		getFolders: builder.query<Folder[], null>({
			query: () => 'getAllFolders'
		}),
		getFolder: builder.query<Folder, string>({
			query: (folderId) => {
				return folderId.length > 0 ? `v2/getFolderInfo/${folderId}` : 'v2/getFolderInfo';
			}
		}),
		getDetailPageData: builder.query<FileResponse, FileRequest>({
			query: ({ cuid }) => ({
				url: 'v2/fileContent',
				method: 'POST',
				body: JSON.stringify({
					cuid
				})
			})
		})
	})
});

export const {
	useGetFileQuery,
	useGetFilesQuery,
	useGetFolderQuery,
	useGetFoldersQuery,
	useGetDetailPageDataQuery,
	useLazyGetFileQuery,
	useLazyGetFilesQuery,
	useLazyGetFolderQuery,
	useLazyGetFoldersQuery,
	useLazyGetDetailPageDataQuery
} = libraryQueries;
