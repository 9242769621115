import { AuthError, signIn } from 'aws-amplify/auth';
import { container, formFieldsContainer } from 'components/Auth/auth.styles';
import { AuthHeader } from 'components/Auth/auth-components';
import { ErrorAlert, ErrorAlertKey } from 'components/Auth/error-alert';
import {
	Button,
	FieldVertical,
	FlexBox,
	FormEnhancer,
	InlineAlert,
	Input,
	RouterLink,
	ScreenReaderOnly,
	Text
} from 'cymantic-ui/dist/atomic-components';
import Cookies from 'js-cookie';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { setAuthError, setAuthInProgress, setSignedIn } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { confirmRoute, dashboardRoute, resetRequestRoute, signUpRoute } from 'routes/helper';
import { hasSignedInName } from 'utilities/cookies';
import { getAppDomain } from 'utilities/get-links';
import { isValidEmail } from 'utilities/string-validation';

export type SignInFormFields = {
	email: string;
	password: string;
};

export const SignIn = () => {
	const form = useForm<SignInFormFields>({
		mode: 'onSubmit'
	});
	const dispatch = useAppDispatch();
	const authError = useAppSelector((state) => state.auth?.authError);
	const { isAuthInProgress, redirectUrl } = useAppSelector((state) => state.auth);
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = form;

	async function signInUser(data: SignInFormFields) {
		if (isAuthInProgress) return;
		const domain = getAppDomain();

		dispatch(setAuthInProgress(true));
		try {
			const username = data.email;
			const res = await signIn({
				username,
				password: data.password
			});

			if (res.isSignedIn) {
				Cookies.set(hasSignedInName, 'true', domain);

				dispatch(setSignedIn(true));
				dispatch(setAuthError(undefined));
				navigate(redirectUrl || `/${dashboardRoute}`);
			}
		} catch (error) {
			if (error instanceof AuthError) {
				if (error.name === 'UserLambdaValidationException') {
					navigate(`/${confirmRoute}`, { state: { email: data.email, isSignIn: true } });
				}
				dispatch(setAuthError(error?.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	}

	return (
		<FormEnhancer
			form={form}
			aria-describedby="sign-in-form-description"
			className={container}
			onSubmit={handleSubmit(signInUser)}
		>
			<AuthHeader title="Sign In">Sign in to continue exploring</AuthHeader>
			<ScreenReaderOnly>
				<div id="sign-in-form-description">All fields are required.</div>
			</ScreenReaderOnly>
			<div className={formFieldsContainer}>
				<FieldVertical>
					<Input
						{...register('email', {
							required: {
								value: true,
								message: 'Please enter a email address'
							},
							validate: (value) =>
								isValidEmail(value) || 'Please enter a valid email address'
						})}
						label="Email"
						autoComplete="email"
						isInvalid={!!errors.email?.type}
						placeholder="Enter email"
					>
						{errors.email && (
							<InlineAlert variant="error" size="xxs">
								{errors.email?.message}
							</InlineAlert>
						)}
					</Input>
				</FieldVertical>
				<FieldVertical>
					<Input
						{...register('password', {
							required: {
								value: true,
								message: 'Please enter a password'
							}
						})}
						label="Password"
						type="password"
						auto-complete="password"
						isInvalid={!!errors.password?.type}
						placeholder="Enter password"
					>
						{errors.password && (
							<InlineAlert variant="error" size="xxs">
								{errors.password?.message}
							</InlineAlert>
						)}
					</Input>
					<FlexBox direction="row-reverse">
						<RouterLink
							to={`/${resetRequestRoute}`}
							variant="bodyXS"
							label="Forgot your password?"
						/>
					</FlexBox>
				</FieldVertical>
			</div>

			{authError && (
				<FlexBox width="100%">
					<ErrorAlert authType="signIn" errorType={authError as ErrorAlertKey} />
				</FlexBox>
			)}

			<Button
				label="Sign In"
				type="submit"
				variant="primary"
				subVariant="default"
				isFullWidth
				isLoading={isAuthInProgress}
				size="md"
			/>

			<Text variant="bodyXS" color="grey600">
				Don’t have an account? <RouterLink to={`/${signUpRoute}`} label="Sign Up" />
			</Text>
		</FormEnhancer>
	);
};
