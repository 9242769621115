import { HomePageAuthRedirect } from 'components/Auth/auth-components';
import { CenteredLayoutContainer } from 'components/CenteredLayout/centered-layout';
import { ShuffleHeader } from 'components/ShuffleHeader/ShuffleHeader';
import { CenterContent } from 'cymantic-ui/dist/atomic-components/center-content';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { dashboardRoute, searchRoute } from 'routes/helper';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const HomePage = () => {
	const { isSignedIn, redirectUrl } = useAppSelector((state) => state.auth);
	const { isAuthEnabled, isShannonEnabled, isLibraryEnabled } = getFeatureFlags();
	const isDashboardDefault = isShannonEnabled || isLibraryEnabled;
	const isAuthed = isSignedIn || !isAuthEnabled;

	return (
		<>
			{isAuthed && isDashboardDefault && (
				<Navigate to={redirectUrl || `/${dashboardRoute}`} replace />
			)}
			{isAuthed && !isDashboardDefault && (
				<Navigate to={redirectUrl || `/${searchRoute}`} replace />
			)}
			{!isAuthed && (
				<CenterContent>
					<CenteredLayoutContainer>
						<ShuffleHeader />
						<HomePageAuthRedirect />
					</CenteredLayoutContainer>
				</CenterContent>
			)}
		</>
	);
};

export default HomePage;
