import AuthWrapper from 'components/Auth/auth-wrapper';
import { ConfirmAccount } from 'components/Auth/confirm-account';
import { ResetPasswordForm } from 'components/Auth/reset-password-form';
import { ResetPasswordRequest } from 'components/Auth/reset-password-request';
import { SignIn } from 'components/Auth/sign-in';
import { SignUp } from 'components/Auth/sign-up';
import { GeneralLoader } from 'components/general-loader/general-loader';
import { GeneralToastNotifications } from 'components/toast-notifications/toast-notifications';
import { FlexBox } from 'cymantic-ui/dist/atomic-components/flex-box';
import { PrivacyPolicy, Terms } from 'cymantic-ui/dist/molecular-components/agreements';
import { lazy, Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import CenteredPageLayout from 'routes/CenteredPageLayout/CenteredPageLayout';
import { DashboardPageSkeleton } from 'routes/DashboardPage/dashboard-page-skeleton';
import { DetailPageSkeleton } from 'routes/detail-page/detail-page-skeleton';
import { PdfFilePage } from 'routes/detail-page/pdf-file';
import { TranscriptFilePage } from 'routes/detail-page/transcript-file/transcript-file';
import { WebFilePage } from 'routes/detail-page/web-file';
import FailedAuthPage from 'routes/FailedAuthPage/FailedAuthPage';
import {
	authRoute,
	contentRoute,
	dashboardRoute,
	libraryRoute,
	meetRoute,
	privacyRoute,
	searchRoute,
	settingsRoute,
	signoutRoute,
	termsRoute
} from 'routes/helper';
import HomePage from 'routes/HomePage/HomePage';
import { LeftSidebarPageLayout } from 'routes/left-sidebar-page-layout';
import { LibraryPageSkeleton } from 'routes/Library/LibraryPage/LibraryPageStates';
import NoMatchPage from 'routes/NoMatchPage/NoMatchPage';
import Protected from 'routes/Protected/Protected';
import { Sandbox } from 'routes/sandbox';
import { SettingsSkeleton } from 'routes/settings-page/settings-skeleton';
import { getFeatureFlags } from 'utilities/get-feature-flags';
import { getProduct } from 'utilities/get-product';
import { isLocalHost } from 'utilities/is-local-host';

const DashboardPage = lazy(() => import('routes/DashboardPage/DashboardPage'));
const SearchPage = lazy(() => import('routes/SearchPage/search-page'));
const LibraryPage = lazy(() => import('routes/Library/LibraryPage/LibraryPage'));
const LibraryFolderPage = lazy(() => import('routes/Library/LibraryFolderPage/LibraryFolderPage'));
const DetailPageLayout = lazy(() => import('routes/detail-page/detail-page-layout'));
const SettingsPage = lazy(() => import('routes/settings-page/settings'));
const MeetRoom = lazy(() => import('routes/meet/room'));

const { isShannonEnabled, isAuthEnabled, isLibraryEnabled } = getFeatureFlags();

const { productName } = getProduct();

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<AuthWrapper>
				<GeneralToastNotifications />
				<Outlet />
			</AuthWrapper>
		),
		children: [
			{
				path: isShannonEnabled ? meetRoute : undefined,
				element: (
					<Suspense fallback={<GeneralLoader>Loading...</GeneralLoader>}>
						<Protected>
							<MeetRoom />
						</Protected>
					</Suspense>
				)
			},
			{
				element: (
					<Protected>
						<LeftSidebarPageLayout />
					</Protected>
				),
				children: [
					{
						path: isShannonEnabled || isLibraryEnabled ? dashboardRoute : undefined,
						element: (
							<Suspense fallback={<DashboardPageSkeleton />}>
								<DashboardPage />
							</Suspense>
						)
					},
					{
						path: searchRoute,
						element: (
							<Suspense fallback={<GeneralLoader>Loading...</GeneralLoader>}>
								<SearchPage />
							</Suspense>
						)
					},
					{
						path: isAuthEnabled ? settingsRoute : undefined,
						element: (
							<Suspense fallback={<SettingsSkeleton />}>
								<SettingsPage />
							</Suspense>
						)
					},
					{
						path: contentRoute,
						element: (
							<Suspense fallback={<DetailPageSkeleton />}>
								<DetailPageLayout />
							</Suspense>
						),
						children: [
							{
								path: 'pdf/:index/:fileId',
								element: <PdfFilePage />
							},
							{
								path: 'web/:index/:fileId',
								element: <WebFilePage />
							},
							{
								path: 'transcript/:index/:fileId',
								element: isShannonEnabled ? <TranscriptFilePage /> : null
							}
						]
					},
					{
						path: isLibraryEnabled ? libraryRoute : undefined,
						element: (
							<Suspense fallback={<LibraryPageSkeleton />}>
								<LibraryPage />
							</Suspense>
						)
					},
					{
						path: isLibraryEnabled ? `${libraryRoute}/folder/:folderId` : undefined,
						element: (
							<Suspense fallback={<LibraryPageSkeleton />}>
								<LibraryFolderPage />
							</Suspense>
						)
					}
				]
			},
			{
				path: 'sandbox/*',
				element: isLocalHost() ? (
					<Suspense fallback={<GeneralLoader>Loading...</GeneralLoader>}>
						<Sandbox />
					</Suspense>
				) : null
			},
			{
				element: <CenteredPageLayout />,
				children: [
					{
						index: true,
						element: <HomePage />
					},
					{
						path: `${signoutRoute}/:cause`,
						element: <FailedAuthPage />
					},
					{
						path: termsRoute,
						element: <Terms product={productName} />
					},
					{
						path: privacyRoute,
						element: <PrivacyPolicy />
					},
					{
						path: isAuthEnabled ? authRoute : undefined,
						element: (
							<FlexBox justifyContent="center" height="100%" alignItems="center">
								<Outlet />
							</FlexBox>
						),
						children: [
							{
								path: 'sign-up',
								element: <SignUp />
							},
							{
								path: 'reset-request',
								element: <ResetPasswordRequest />
							},
							{
								path: 'reset-form',
								element: <ResetPasswordForm />
							},
							{
								path: 'sign-in',
								element: <SignIn />
							},
							{
								path: 'confirm',
								element: <ConfirmAccount />
							}
						]
					},
					{
						path: '*',
						element: <NoMatchPage />
					}
				]
			}
		]
	}
]);
