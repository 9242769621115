import { resendSignUpCode, resetPassword } from 'aws-amplify/auth';
import { toast } from 'components/toast-notifications/toast-notifications';
import { Alert, Link, LinkButton, RouterLink, Text } from 'cymantic-ui/dist/atomic-components';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthError } from 'redux/features/auth';
import { resetRequestRoute } from 'routes/helper';
import { formatEmail } from 'utilities/string';

type ErrorAlertDetails = {
	textHeader: string;
	textBody: string;
	request?: string;
	externalLink?: string;
	callback?: (email: string) => void;
	to?: string;
	internalLink?: string;
};

const ErrorAlertKeys = [
	'UsernameExistsException',
	'accountCreationError',
	'ServiceUnavailableException',
	'NotAuthorizedException',
	'passwordFormError',
	'NetworkError',
	'InternalErrorException'
] as const;

export type ErrorAlertKey = (typeof ErrorAlertKeys)[number];

const AuthTypeKeys = [
	'signIn',
	'signUp',
	'resetPassword',
	'resetPasswordRequest',
	'confirmAccount',
	'resendCode',
	'updatePassword'
] as const;
export type AuthType = (typeof AuthTypeKeys)[number];

export function useErrorMessage() {
	const handleSendNewResetPasswordCode = useCallback(async (email?: string) => {
		if (!email) return;
		try {
			await resetPassword({ username: email });
			toast.success({
				content: 'Check your email for your new reset password code'
			});
		} catch (error) {
			toast.error({
				content: 'New code failed to send. Please try again later'
			});
		}
	}, []);

	const handleSendNewCode = useCallback(async (email?: string) => {
		if (!email) return;
		try {
			await resendSignUpCode({ username: email });
			toast.success({
				content: 'Check your email for your new confirmation code'
			});
		} catch (error) {
			toast.error({
				content: 'New code failed to send. Please try again later'
			});
		}
	}, []);

	const getErrorMessageByKey = useCallback(
		(errorKey: ErrorAlertKey, action: AuthType, email?: string): ErrorAlertDetails | null => {
			const combined = `${errorKey}:${action}`;
			const formatedEmail = email ? formatEmail(email) : 'your email address';

			switch (combined) {
				case 'UsernameExistsException:signUp':
					return {
						textHeader: 'An account with this email address already exists',
						textBody: 'If you have forgotten your password, you may ',
						internalLink: 'request a password reset',
						to: `/${resetRequestRoute}`
					};
				case 'NetworkError:signUp':
				case 'InternalErrorException:signUp':
				case 'ServiceUnavailableException:signUp':
					return {
						textHeader: 'Unable to create your account at this time',
						textBody:
							'An error occurred while creating your account. You may retry, or try again later. If the issue persists, ',
						externalLink: 'contact us'
					};
				case 'NetworkError:resetPasswordRequest':
				case 'InternalErrorException:resetPasswordRequest':
				case 'ServiceUnavailableException:resetPasswordRequest':
					return {
						textHeader: 'Unable to send code at this time',
						textBody:
							'This may be a technical issue on our end. If this issue persists, ',
						externalLink: 'contact us'
					};
				case 'NetworkError:signIn':
				case 'InternalErrorException:signIn':
				case 'ServiceUnavailableException:signIn':
					return {
						textHeader: 'Unable to sign in at this time',
						textBody:
							'This may be a technical issue on our end. If this issue persists ',
						externalLink: 'contact us'
					};
				case 'NotAuthorizedException:signIn':
					return {
						textHeader: 'Incorrect email or password',
						textBody: 'The email or password entered does not match our records'
					};
				case 'NetworkError:resetPassword':
				case 'InternalErrorException:resetPassword':
				case 'ServiceUnavailableException:resetPassword':
					return {
						textHeader: 'Unable to reset password at this time',
						textBody:
							'This may be a technical issue on our end. If this issue persists, ',
						externalLink: 'contact us'
					};
				case 'ExpiredCodeException:resetPassword':
					return {
						textHeader: 'The reset code you provided has expired',
						textBody: `Please `,
						request: 'request a new code',
						callback: handleSendNewResetPasswordCode
					};

				case 'CodeMismatchException:resetPassword':
					return {
						textHeader: 'The reset code you provided is not valid',
						textBody: `Please provide the code you received to your email at ${formatedEmail}. If you would like you may `,
						request: 'request a new code',
						callback: handleSendNewResetPasswordCode
					};
				case 'NotAuthorizedException:confirmAccount':
				case 'NetworkError:confirmAccount':
				case 'InternalErrorException:confirmAccount':
				case 'ServiceUnavailableException:confirmAccount':
					return {
						textHeader: 'Unable to confirm account at this time',
						textBody:
							'An error occurred while confirming your account. You may retry, or try again later. If the issue persists, ',
						externalLink: 'contact us'
					};
				case 'NotAuthorizedException:updatePassword':
					return {
						textHeader: 'Incorrect password',
						textBody:
							'The password you entered does not match our records. If you’ve forgotten your password, you may select  “Forgot your password?” next time you sign in'
					};
				case 'NetworkError:updatePassword':
				case 'InternalErrorException:updatePassword':
				case 'ServiceUnavailableException:updatePassword':
					return {
						textHeader: 'Unable to update password at this time',
						textBody:
							'This may be a technical issue on our end. If this issue persists, ',
						externalLink: 'contact us'
					};
				case 'LimitExceededException:confirmAccount':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowed limit of account confirmation attempts at this time. Please try again later'
					};
				case 'LimitExceededException:resetPassword':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowed limit of password reset attempts at this time. Please try again later'
					};
				case 'LimitExceededException:signUp':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowed limit of sign up attempts at this time. Please try again later'
					};
				case 'LimitExceededException:resetPasswordRequest':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowed limit of attempts time. Please try again later'
					};
				case 'LimitExceededException:signin':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowed limit of sign in attempts at this time. Please try again later'
					};
				case 'LimitExceededException:updatePassword':
					return {
						textHeader: 'Attempt limit exceeded',
						textBody:
							'You have reached the maximum allowExpiredCodeExceptioned limit of password update attempts at this time. Please try again later'
					};
				case 'ExpiredCodeException:confirmAccount':
					return {
						textHeader: 'The verification code your provided has expired',
						textBody: `Please `,
						request: 'request a new code',
						callback: handleSendNewCode
					};
				case 'CodeMismatchException:confirmAccount':
					return {
						textHeader: 'The verification code you provided is not valid',
						textBody: `Please provide the code you received to your email at ${formatedEmail}. If you would like you may `,
						request: 'send a new code',
						callback: handleSendNewCode
					};
				default:
					return null;
			}
		},
		[handleSendNewCode, handleSendNewResetPasswordCode]
	);
	return { getErrorMessageByKey, handleSendNewResetPasswordCode, handleSendNewCode };
}

type ErrorAlertProps = {
	errorType: ErrorAlertKey;
	authType: AuthType;
	email?: string;
};

export const ErrorAlert = ({ errorType, authType, email }: ErrorAlertProps) => {
	const { getErrorMessageByKey } = useErrorMessage();
	const errorMessage = getErrorMessageByKey(errorType, authType, email);
	const dispatch = useDispatch();

	if (!errorMessage) {
		return null;
	}

	const { textHeader, textBody, request, externalLink, callback, internalLink, to } =
		errorMessage;

	const handleLinkButtonClick = () => {
		if (callback && email) {
			callback(email);
			dispatch(setAuthError(undefined));
		}
	};

	return (
		<Alert variant="error" title={textHeader} isDismissable={false}>
			<Text variant="bodyXS" color="grey600">
				{textBody}
				{internalLink && to && (
					<RouterLink
						onClick={() => dispatch(setAuthError(undefined))}
						to={to}
						variant="bodyXS"
						label={internalLink}
					/>
				)}
				{request && callback && (
					<LinkButton onClick={handleLinkButtonClick} variant="bodyXS" label={request} />
				)}
				{externalLink && (
					<Link
						href="mailto:support@cymantix.com"
						variant="bodyXS"
						label={externalLink}
					/>
				)}
				.
			</Text>
		</Alert>
	);
};
