import { css, keyframes } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

const circleAnimateIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.5) rotate(-8deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
`;

export const clusterView = {
	root: css`
		display: flex;
		justify-content: center;
		position: relative;
		width: 100%;
	`,
	transcriptLink: css`
		bottom: 1.5rem;
		left: 1.5rem;
		position: absolute;
	`,
	loaderOverlay: css`
		background: rgb(255 255 255 / 50%);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 100;
	`,
	loaderContainer: css`
		background: ${theme.color.white};
		border-radius: ${theme.borderRadius.radiusSM};
		box-shadow: ${theme.shadow.shadow500};
		padding: ${theme.space.space16};
	`,
	clusterVisualization: css`
		height: 100%;
		width: 100%;

		.node {
			.animate-node-in {
				animation-duration: 900ms;
				animation-fill-mode: forwards;
				animation-name: ${circleAnimateIn};
				animation-timing-function: cubic-bezier(0.7, 0.85, 0.41, 1.21);
				opacity: 0;
			}

			circle {
				cursor: pointer;
				filter: drop-shadow(1px 3px 6px rgb(62 92 224 / 20%));
				transition: transform 200ms ease-in-out;
			}

			&:hover circle {
				transform: scale(1.05);
			}
		}

		.tooltip.tooltip--visible {
			border: 1px solid var(--cy-color-grey200);
			max-height: none;
			opacity: 1;
			z-index: 200;
		}

		.tooltip {
			/* 		Other styles */
			background: var(--cy-color-white);
			border: 0 solid transparent;
			border-radius: var(--cy-borderRadius-radiusSM);
			color: var(--cy-color-grey800);
			font-size: var(--cy-fontSize-bodyXXS);

			/* 		Needed for animation */
			max-height: 0;
			max-width: 20rem;
			min-width: 15rem;
			opacity: 0;
			overflow: hidden;
			position: fixed;

			/* TODO: make keyframes that also fades out */
			transition: max-height 0s, opacity 300ms linear;

			.tooltip__content {
				padding: var(--cy-space-space16);
			}

			.tooltip__label-tags {
				line-height: 2.5em;
			}

			.tooltip__label-tag {
				background: #dfdfe0;
				border-radius: 20px;
				font-size: 10px;
				margin-right: 5px;
				padding: 5px 10px;
				width: auto;
			}

			.tooltip__relevance-text {
				margin-top: 0.5rem;
			}

			.tooltip__bold-text {
				font-weight: bold;
				padding-bottom: 5px;
				padding-top: 0.5em;
			}
		}
	`
};
