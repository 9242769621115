import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { GeneralLoader } from 'components/general-loader/general-loader';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRedirectUrl, setSignedIn } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const { isAuthInProgress, isSignedIn, redirectUrl } = useAppSelector((state) => state.auth);
	const [isLoading, setIsLoading] = React.useState(true);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const isAuthenticated = React.useMemo(() => isSignedIn, [isSignedIn]);

	React.useEffect(() => {
		const checkUser = async () => {
			setIsLoading(true);
			try {
				await getCurrentUser();
				dispatch(setSignedIn(true));
			} catch {
				await signOut({ global: true });
				dispatch(setSignedIn(false));
				dispatch(setRedirectUrl(`${location.pathname}${location.search}`));
			} finally {
				setIsLoading(false);
			}
		};
		if (!isAuthenticated) {
			checkUser();
		}
	}, [dispatch, isAuthenticated, location.pathname, location.search, navigate]);

	React.useEffect(() => {
		/* prevent auth routes if signed in */
		const isOnAuthRoute = location.pathname.startsWith('/auth');
		if (isAuthenticated && isOnAuthRoute) {
			navigate(redirectUrl || '/dashboard', { replace: true, state: { from: location } });
		}
	}, [isAuthenticated, location, navigate, redirectUrl]);

	return (
		<>
			{isLoading && !isAuthInProgress && <GeneralLoader>Loading...</GeneralLoader>}
			{!isLoading && children}
		</>
	);
};

export default AuthProvider;
