import { Unauthorized } from 'components/Auth/auth-components';
import { checkIsUnauthorized } from 'components/Auth/auth-helper';
import { PageSectionError } from 'components/PageSectionError/PageSectionError';
import { SummarySettingsModal } from 'components/summary-settings-modal';
import { InlineAlert, SkeletonAreaLoader, Text } from 'cymantic-ui/dist/atomic-components';
import * as React from 'react';
import { FileResponse } from 'redux/features/library';
import { useLazyGetSingleDocSummaryQuery } from 'redux/features/summary';
import { useAppSelector } from 'redux/hooks';
import { summaryCardStyles } from 'routes/detail-page/detail-page.styles';
import { getSummaryMethod, summaryTypeText } from 'utilities/summaries';

type DetailPageSummaryCardProps = { file: FileResponse };

export const DetailPageSummaryCard = ({
	file: { cuid: fileId, summaryMethods, contentField }
}: DetailPageSummaryCardProps) => {
	const { type } = useAppSelector((state) => state.summary);

	const { availableMethod, canSummarize, availableType, hasBothSummaryMethods } =
		getSummaryMethod(summaryMethods, type);

	const [
		getSingleDocSummary,
		{
			data: summaryData,
			error: summaryError,
			isLoading: isSummaryLoading,
			isFetching: isSummaryFetching,
			isError: isSummaryError
		}
	] = useLazyGetSingleDocSummaryQuery();

	const hasContent = !!contentField;
	const canAttemptSummary = canSummarize && !hasContent;

	React.useEffect(() => {
		if (fileId && canAttemptSummary) {
			getSingleDocSummary({ document_id: fileId, method: availableMethod });
		}
	}, [availableMethod, getSingleDocSummary, canAttemptSummary, fileId]);

	const hasContentReturned =
		canAttemptSummary && !summaryData?.is_summarized && !!summaryData?.content;
	const hasSummaryReturned =
		canAttemptSummary && !!summaryData?.is_summarized && !!summaryData?.content;

	const isLoading = isSummaryLoading || isSummaryFetching;

	if (checkIsUnauthorized(summaryError)) {
		return <Unauthorized />;
	}

	return hasContent || canAttemptSummary ? (
		<div className={summaryCardStyles.root}>
			{canAttemptSummary && (
				<div className={summaryCardStyles.header}>
					<div className={summaryCardStyles.headerInner}>
						<Text variant="bodyXS" tag="h3" color="grey800">
							Summary
						</Text>
						<Text variant="bodyXXS" tag="div" color="grey600">
							{summaryTypeText[availableType]}
						</Text>
					</div>

					{hasBothSummaryMethods && <SummarySettingsModal />}
				</div>
			)}

			<div className={summaryCardStyles.content}>
				{hasContent && <p>{`"${contentField}"`}</p>}
				{isLoading && <SkeletonAreaLoader width="100%" height="13rem" />}
				{!isLoading && (
					<>
						{isSummaryError && (
							<PageSectionError
								title="An error occurred while generating this summary"
								alertVariant="error"
								subtitle="Please try again later."
							/>
						)}
						{hasSummaryReturned && (
							<Text tag="p" variant="bodySM" color="grey800">
								{summaryData.content}
							</Text>
						)}
						{hasContentReturned && (
							<>
								<InlineAlert variant="info" size="xxs">
									The content of the document was too short to summarize. Document
									content is shown below.
								</InlineAlert>
								<p>{`"${summaryData?.content}"`}</p>
							</>
						)}
					</>
				)}
			</div>
		</div>
	) : null;
};
