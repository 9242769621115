import { css } from '@emotion/css';
import { mq } from 'cymantic-ui/dist/constants';
import { theme } from 'cymantic-ui/dist/theme';

export const pageContent = {
	root: css`
		height: 100%;
		overflow-y: auto;
	`,
	innerRow: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space32};
		min-height: min-content;

		${mq.tabletLarge} {
			flex-direction: row;
		}
	`,
	innerColumn: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space32};
		min-height: min-content;
	`
};

export const pageContentRow = css`
	display: grid;
	grid-gap: ${theme.space.space32};
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	width: 100%;

	${mq.tabletSmall} {
		flex-direction: row;
		grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
		grid-template-rows: auto;
	}
`;
