import { PageContent, PageContentRow } from 'components/page-content/page-content';
import { PageHeader, PageHeaderLeftSkeleton } from 'components/PageHeader/PageHeader';
import { TableSkeleton } from 'components/Table/helpers';
import { FlexBox, SkeletonAreaLoader, SkeletonTextLoader } from 'cymantic-ui';
import { getFeatureFlags } from 'utilities/get-feature-flags';

const { isShannonEnabled, isLibraryEnabled } = getFeatureFlags();

export const DashboardPageSkeleton = () => {
	return (
		<>
			<PageHeader>
				<PageHeaderLeftSkeleton />
			</PageHeader>
			<PageContent>
				<FlexBox gap="space16">
					<SkeletonTextLoader width="10rem" variant="headerH3" />
					<SkeletonTextLoader width="20rem" variant="bodySM" />
				</FlexBox>
				{isShannonEnabled && (
					<PageContentRow>
						<SkeletonAreaLoader height="10rem" width="100%" />
						<SkeletonAreaLoader height="10rem" width="100%" />
					</PageContentRow>
				)}
				{isLibraryEnabled && <TableSkeleton />}
			</PageContent>
		</>
	);
};
