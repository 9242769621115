import { getFeatureFlags } from 'utilities/get-feature-flags';

const { isShannonEnabled, isLibraryEnabled } = getFeatureFlags();

/* Auth */
export const signInRoute = 'auth/sign-in';
export const signUpRoute = 'auth/sign-up';
export const resetFormRoute = 'auth/reset-form';
export const resetRequestRoute = 'auth/reset-request';
export const confirmRoute = 'auth/confirm';

/* TODO: update all uses of the below routes as strings to use these consts instead - ex: "/library" is used in some RouterLinks */
/* Parent routes */
export const meetRoute = 'convene';
export const dashboardRoute = 'dashboard';
export const searchRoute = 'search';
export const settingsRoute = 'settings';
export const contentRoute = 'content';
export const libraryRoute = 'library';
export const termsRoute = 'terms';
export const privacyRoute = 'privacy';
export const authRoute = 'auth';
export const signoutRoute = 'signout';

export const protectedRoutes = [
	meetRoute,
	dashboardRoute,
	searchRoute,
	settingsRoute,
	contentRoute,
	libraryRoute
];

/* User's homepage based on feature flags  */
export const userHomeRoute = `/${
	isShannonEnabled || isLibraryEnabled ? dashboardRoute : searchRoute
}`;
