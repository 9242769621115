import Cookies from 'js-cookie';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { signInRoute, signUpRoute } from 'routes/helper';
import { hasSignedInName } from 'utilities/cookies';
import { getFeatureFlags } from 'utilities/get-feature-flags';

type ProtectedProps = {
	children: React.ReactNode;
};

const Protected = ({ children }: ProtectedProps) => {
	const isSignedIn = useAppSelector((state) => state.auth.isSignedIn);
	const { isAuthEnabled } = getFeatureFlags();
	const hasSignedIn = Cookies.get(hasSignedInName) === 'true';

	const isAuthed = isSignedIn || !isAuthEnabled;

	return (
		<>
			{isAuthed && children}
			{!isAuthed && (
				<Navigate to={hasSignedIn ? `/${signInRoute}` : `/${signUpRoute}`} replace />
			)}
		</>
	);
};

export default Protected;
