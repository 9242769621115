const isFlagOn = (flag?: string) => {
	return !!flag && flag.toLowerCase() === 'true';
};

export function getFeatureFlags() {
	const authFlag = process.env.REACT_APP_AUTHENTICATION_FLAG;
	const libraryFlag = process.env.REACT_APP_LIBRARY_FLAG;
	const shannonFlag = process.env.REACT_APP_SHANNON_FLAG;

	const featureFlags = {
		isAuthEnabled: isFlagOn(authFlag),
		// There could be a scenario where library is enabled without auth
		isLibraryEnabled: isFlagOn(libraryFlag),
		// There shouldn't be a scenario where Shannon is enabled without auth
		isShannonEnabled: isFlagOn(shannonFlag) && isFlagOn(authFlag)
	};

	return featureFlags;
}
