import { flexRender, Table as TanstackTable } from '@tanstack/react-table';

type TableProps<TableDataType> = {
	className: string;
	caption?: string;
	table: TanstackTable<TableDataType>;
} & React.HTMLAttributes<HTMLTableElement>;

/**
 * TODO: Look into aria-live="polite" & role="status" for table caption updates (ex: updating page and sort)
 */
export function Table<T>({ className, table, caption, ...tableProps }: TableProps<T>) {
	return (
		<table className={className} {...tableProps}>
			{caption && <caption>{caption}</caption>}
			<thead>
				{table.getHeaderGroups().map((headerGroup) => (
					<tr key={headerGroup.id}>
						{headerGroup.headers.map((header) => (
							<th key={header.id}>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext()
									  )}
							</th>
						))}
					</tr>
				))}
			</thead>
			<tbody>
				{table.getRowModel().rows.map((row) => (
					<tr key={row.id}>
						{row.getVisibleCells().map((cell) => (
							<td key={cell.id}>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}
