import CryptoJS from 'crypto-js';

export const generatedIV = () => CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

export const encrypt = (message: string, secretKey: string, iv: string) => {
	const hexIV = CryptoJS.enc.Hex.parse(iv);

	return CryptoJS.AES.encrypt(message, secretKey, {
		iv: hexIV
	}).toString();
};

export const decrypt = (ciphertext: string, secretKey: string) => {
	try {
		const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
		const decryptedMessage = bytes.toString(CryptoJS.enc.Utf8);
		return decryptedMessage;
	} catch (e) {
		return '';
	}
};
