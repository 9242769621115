import amplifyconfig from 'amplifyconfiguration.json';
import { Amplify } from 'aws-amplify';
import { RouterProvider } from 'react-router-dom';
import { router } from 'routes/router';
import { getProduct } from 'utilities/get-product';

Amplify.configure(amplifyconfig);

const { productName } = getProduct();

const App = () => {
	if (productName) {
		document.title = `${productName} - See the big picture in you data`;
	}

	return <RouterProvider router={router} />;
};

export default App;
