import {
	SingleDocSummaryRequest,
	SingleDocSummaryResponse
} from 'redux/features/summary/summary.definitions';
import { summaryApiBase } from 'redux/summary-api-base';

export const summaryQueries = summaryApiBase.injectEndpoints({
	endpoints: (builder) => ({
		getSingleDocSummary: builder.query<SingleDocSummaryResponse, SingleDocSummaryRequest>({
			query: (summaryOptions) => {
				return {
					url: `/text/single-content`,
					method: 'POST',
					body: JSON.stringify(summaryOptions)
				};
			}
		})
	})
});

export const { useGetSingleDocSummaryQuery, useLazyGetSingleDocSummaryQuery } = summaryQueries;
