import { css } from '@emotion/css';
import { theme } from 'cymantic-ui/dist/theme';

export const webFileStyles = {
	container: css`
		align-items: center;
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		position: relative;
	`,
	link: css`
		background-color: ${theme.color.grey100};
		border: ${theme.border.default};
		border-color: ${theme.color.grey300};
		border-radius: ${theme.borderRadius.radiusMD};
		box-shadow: ${theme.shadow.shadow100};
		box-sizing: border-box;
		overflow: hidden;
		position: relative;
		width: 100%;

		&:hover {
			background-color: ${theme.color.grey200};
			box-shadow: ${theme.shadow.shadow200};
		}
	`,
	content: css`
		display: flex;
		flex-direction: column;
		overflow: hidden;
		position: relative;
	`,
	imageContainer: css`
		align-items: center;
		aspect-ratio: 1.91 / 1;
		display: flex;
		justify-content: center;

		image {
			height: min-content;
			width: auto;
		}
	`,
	linkIconContainer: css`
		background-color: ${theme.color.white};
		border-bottom-left-radius: ${theme.borderRadius.radiusMD};
		padding: ${theme.space.space16};
		position: absolute;
		right: 0;
		top: 0;
	`
};

export const pdfFileStyles = {
	pdf: css`
		aspect-ratio: 8.5 / 11;
		max-height: 100%;
	`
};

export const summaryCardStyles = {
	root: css`
		background-color: ${theme.color.white};
		border: 1px solid ${theme.color.grey300};
		border-radius: ${theme.borderRadius.radiusMD};
	`,
	header: css`
		align-items: flex-start;
		background-color: ${theme.color.grey50};
		border-radius: ${theme.borderRadius.radiusMD} ${theme.borderRadius.radiusMD} 0 0;
		display: flex;
		gap: ${theme.space.space24};
		justify-content: space-between;
		padding: ${theme.space.space24};
	`,
	headerInner: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space4};
	`,
	content: css`
		display: flex;
		flex-direction: column;
		gap: ${theme.space.space16};
		padding: ${theme.space.space24};
	`
};
