import { AuthError, resetPassword } from 'aws-amplify/auth';
import { container, formFieldsContainer } from 'components/Auth/auth.styles';
import { AuthHeader } from 'components/Auth/auth-components';
import { ErrorAlert, ErrorAlertKey } from 'components/Auth/error-alert';
import {
	Button,
	FieldVertical,
	FlexBox,
	FormEnhancer,
	InlineAlert,
	Input,
	ScreenReaderOnly
} from 'cymantic-ui/dist/atomic-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { setAuthError, setAuthInProgress } from 'redux/features/auth';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { resetFormRoute } from 'routes/helper';
import { isValidEmail } from 'utilities/string-validation';

export type ResetPasswordFormFields = {
	email: string;
};

export const ResetPasswordRequest = () => {
	const navigate = useNavigate();
	const authError = useAppSelector((state) => state.auth?.authError);
	const { isAuthInProgress } = useAppSelector((state) => state.auth);
	const form = useForm<ResetPasswordFormFields>({ mode: 'onSubmit' });

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = form;
	const dispatch = useAppDispatch();

	const handleResetPasswordRequest = async (data: ResetPasswordFormFields) => {
		if (isAuthInProgress) return;
		dispatch(setAuthInProgress(true));
		try {
			await resetPassword({ username: data.email });
			dispatch(setAuthError(undefined));
			navigate(`/${resetFormRoute}`, { state: { email: data.email } });
		} catch (error) {
			if (error instanceof AuthError) {
				dispatch(setAuthError(error?.name));
			}
		} finally {
			dispatch(setAuthInProgress(false));
		}
	};

	return (
		<FormEnhancer
			form={form}
			aria-describedby="resset-password-request-form-description"
			className={container}
			onSubmit={handleSubmit(handleResetPasswordRequest)}
		>
			<>
				<AuthHeader title="Reset Password">
					Enter your email to request a password reset.
				</AuthHeader>
				<ScreenReaderOnly>
					<div id="resset-password-request-form-description">
						All fields are required.
					</div>
				</ScreenReaderOnly>
				<div className={formFieldsContainer}>
					<FieldVertical>
						<Input
							{...register('email', {
								required: {
									value: true,
									message: 'Enter your email to receive a password reset code.'
								},
								validate: (value) =>
									isValidEmail(value) || 'Please enter a valid email address'
							})}
							label="Email"
							autoComplete="email"
							isInvalid={!!errors.email?.type}
							placeholder="Enter email"
						>
							{errors.email && (
								<InlineAlert variant="error" size="xxs">
									{errors.email?.message}
								</InlineAlert>
							)}
						</Input>
					</FieldVertical>
				</div>
				{authError && (
					<FlexBox width="100%">
						<ErrorAlert
							authType="resetPasswordRequest"
							errorType={authError as ErrorAlertKey}
						/>
					</FlexBox>
				)}

				<Button
					label="Send Reset Code"
					type="submit"
					variant="primary"
					isFullWidth
					isLoading={isAuthInProgress}
					subVariant="default"
					size="md"
				/>
			</>
		</FormEnhancer>
	);
};
