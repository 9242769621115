import { signOut } from 'aws-amplify/auth';
import { useAppDispatch } from 'redux/hooks';
import { resetReduxState } from 'redux/root-reducer';

/**
 * WARNING: This hook MUST be in a .TSX file.
 * CHANGING THIS TO A .TS FILE WILL BRICK THE APP.
 */
export function useSignOut() {
	const dispatch = useAppDispatch();

	const handleSignOut = async () => {
		await signOut({ global: true });

		dispatch(resetReduxState());
	};

	return { handleSignOut };
}
