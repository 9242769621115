import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState, User } from 'redux/features/auth/auth.definitions';
import { protectedRoutes } from 'routes/helper';

const initialState: AuthState = {
	isSignedIn: false,
	isAuthInProgress: false,
	authError: undefined,
	redirectUrl: undefined,
	user: {}
};

export const authSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setUserData: (state, action: PayloadAction<User>) => {
			const userData = action.payload;

			const returnState: AuthState = {
				...state,
				user: {
					...state.user,
					...userData
				}
			};

			return returnState;
		},
		setSignedIn: (state, action: PayloadAction<boolean>) => {
			const isSignedIn = action.payload;

			const returnState: AuthState = {
				...state,
				isSignedIn
			};

			return returnState;
		},
		setAuthInProgress: (state, action: PayloadAction<boolean>) => {
			const isAuthInProgress = action.payload;

			const returnState: AuthState = {
				...state,
				isAuthInProgress
			};

			return returnState;
		},
		setAuthError: (state, action: PayloadAction<string | undefined>) => {
			const authErrorName = action.payload;

			const returnState: AuthState = {
				...state,
				authError: authErrorName
			};

			return returnState;
		},
		setRedirectUrl: (state, action: PayloadAction<string | undefined>) => {
			const redirectUrl = action.payload;

			const isProtectedRoute = protectedRoutes.some((route) => redirectUrl?.includes(route));

			const containsLetters = /[a-zA-Z]/.test(redirectUrl || '');

			if (!containsLetters || !isProtectedRoute) {
				return state;
			}

			const returnState: AuthState = {
				...state,
				redirectUrl
			};
			return returnState;
		}
	}
});

export const { setUserData, setSignedIn, setAuthInProgress, setAuthError, setRedirectUrl } =
	authSlice.actions;
export const authReducer = authSlice.reducer;
