import { Unauthorized } from 'components/Auth/auth-components';
import { checkIsUnauthorized } from 'components/Auth/auth-helper';
import { PageContent, PageContentColumn } from 'components/page-content/page-content';
import { SkeletonAreaLoader } from 'cymantic-ui/dist/atomic-components';
import { useParams } from 'react-router-dom';
import { PdfContent, useGetDetailPageDataQuery } from 'redux/features/library';
import { pdfFileStyles } from 'routes/detail-page/detail-page.styles';
import { DetailPageSummaryCard } from 'routes/detail-page/detail-page-summary-card';
import { Error } from 'routes/SearchPage/ClusterView/ClusterStates';

export const PdfFilePage = () => {
	const { fileId = '' } = useParams();

	const { data, error, isLoading } = useGetDetailPageDataQuery(
		{
			cuid: fileId
		},
		/* Note: Backend routes are updated every 5 minutes. Ensure to refetch routes at this interval to maintain up-to-date information. */
		{ pollingInterval: 1000 * 60 * 4.6 }
	);

	const hasError = !isLoading && !!error;
	const content = data?.content as PdfContent;
	const hasPDF = !isLoading && !hasError && content?.url;

	if (checkIsUnauthorized(error)) {
		return <Unauthorized />;
	}

	return (
		<PageContent direction="row">
			<PageContentColumn>
				{isLoading && <SkeletonAreaLoader width="100%" height="100%" />}
				{hasError && <Error />}
				{hasPDF && (
					<div className={pdfFileStyles.pdf}>
						<iframe
							src={`${content?.url}#zoom=45`}
							title={data?.name ?? ''}
							width="100%"
							height="100%"
						/>
					</div>
				)}
			</PageContentColumn>
			<PageContentColumn>
				{data && !isLoading && <DetailPageSummaryCard file={data} />}

				{isLoading && <SkeletonAreaLoader width="100%" height="20rem" />}
			</PageContentColumn>
		</PageContent>
	);
};
