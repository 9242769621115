import { SummaryMethodsModel } from 'redux/features/cluster';
import { RuthiSummaryType, SummaryMethod } from 'redux/features/summary/summary.definitions';

export const summaryTypeText: Record<RuthiSummaryType, string> = {
	abstractive:
		'Summaries are generated by AI using the content from the original text as a reference and may display inaccurate information.',
	extractive: 'Summaries are constructed from sentences present in original text.'
};

export const getSummaryMethod = (summaryMethods: SummaryMethodsModel, type: RuthiSummaryType) => {
	const hasExtractiveMethods =
		summaryMethods?.extractive && summaryMethods?.extractive !== 'none';
	const hasAbstractiveMethods =
		summaryMethods?.abstractive && summaryMethods?.abstractive !== 'none';
	const hasBothSummaryMethods = hasExtractiveMethods && hasAbstractiveMethods;

	const canSummarize = hasExtractiveMethods || hasAbstractiveMethods;
	const bothMethod = type.includes('abstract') ? 'abstract' : 'extract';
	const oneMethod = hasAbstractiveMethods ? 'abstract' : 'extract';
	const availableMethod: SummaryMethod = hasBothSummaryMethods ? bothMethod : oneMethod;
	const availableType: RuthiSummaryType = `${availableMethod}ive`;

	return {
		canSummarize,
		hasAbstractiveMethods,
		hasBothSummaryMethods,
		hasExtractiveMethods,
		availableMethod,
		availableType
	};
};
