import { useSignOut } from 'components/Auth/use-sign-out';
import { DropdownButton } from 'cymantic-ui/dist/atomic-components/dropdown';
import { UserMenu } from 'cymantic-ui/dist/molecular-components/user-menu';
import { useAppSelector } from 'redux/hooks';

export const UserDropdown = () => {
	const user = useAppSelector((state) => state.auth.user);

	const { handleSignOut } = useSignOut();

	return (
		<UserMenu userName={user.name} userEmail={user.email}>
			<DropdownButton label="Sign Out" onClick={handleSignOut} />
		</UserMenu>
	);
};
