import { isLocalHost } from 'cymantic-ui/dist/utilities';
import { meetRoute } from 'routes/helper';

const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN ?? 'discoveros.com';

const STAGE = process.env.REACT_APP_STAGE === 'prod' ? '' : `${process.env.REACT_APP_STAGE}.`;

export const getAppDomain = () => (isLocalHost() ? {} : { domain: `${STAGE}${COOKIE_DOMAIN}` });

export const getAppLink = () =>
	isLocalHost() ? 'https://localhost:3000' : `https://${STAGE}${COOKIE_DOMAIN}`;

export const getRoomLink = (roomId: string) => `/${meetRoute}?roomId=${roomId}`;

export const getFullRoomLink = (roomId: string) => `${getAppLink()}${getRoomLink(roomId)}`;
