import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthTokens } from 'components/Auth/auth-helper';
import { generateRequestSignature } from 'sign';

const HOST = process.env.REACT_APP_API_HOST;

const baseQuery = fetchBaseQuery({
	baseUrl: `${HOST}/api/`,
	prepareHeaders: async (headers) => {
		headers.set('Content-Type', 'application/json');

		const signature = await generateRequestSignature();
		const { tokenId, accessToken } = await getAuthTokens();

		headers.set('X-RUTHI-REQUEST-ID', signature.requestId);
		headers.set('X-Ruthi-REQUEST-TOKEN', signature.token);

		headers.set('X-RUTHI-AUTHORIZATION', `Bearer ${tokenId}`);
		headers.set('X-RUTHI-ACCESS-TOKEN', accessToken ?? '');

		return headers;
	}
});

/**
 * This is the base api
 * @use TO ADD ENDPOINTS - features/myfeature/feature-queries.ts => apiBase.injectEndpoints
 */
export const ruthiApiBase = createApi({
	reducerPath: 'ruthi-api',
	baseQuery,
	endpoints: () => ({})
});
