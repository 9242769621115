import { PageContent } from 'components/page-content/page-content';
import { PageHeader, PageHeaderLeftSkeleton } from 'components/PageHeader/PageHeader';
import { FlexBox, SkeletonAreaLoader } from 'cymantic-ui';

export const DetailPageSkeleton = () => {
	return (
		<FlexBox height="100%">
			<PageHeader>
				<PageHeaderLeftSkeleton hasBackLink />
			</PageHeader>
			<PageContent direction="row">
				<SkeletonAreaLoader height="20rem" width="100%" />
				<SkeletonAreaLoader height="20rem" width="100%" />
			</PageContent>
		</FlexBox>
	);
};
